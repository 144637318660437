import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import bg1img from "../../Assets/Images/bg1img.png";
import img3blue from "../../Assets/Images/img3blue.png";
import a1 from "../../Assets/Images/a1.png";
import b1 from "../../Assets/Images/b1.png";
import c1 from "../../Assets/Images/c1.png";
import d1 from "../../Assets/Images/d1.png";
import landd1 from "../../Assets/Images/landd1.png";
import Slider from "../../Components/Productslider";

const Ourproducts = () => {
    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        fade: true,
        nav: true,
        slidesToScroll: 1,
      };
  return (
    <div id="mainwrapper" className="ourproducts">
      
      <Slider />
           <section className="gaiatoksect">
        <div className="container">
          <div className="columns2 colwdth">
            <div className="col textpadl">
              <h3>GAIA TOKEN</h3>
              <p>
                Gaia Everworld is the immersive, multi-region fantasy world in
                which players build their kingdoms, explore the lands, collect,
                breed and battle their Gaia Legionnaires.
              </p>
              <Link
                to="/gaiatoken/#mainwrapper"
                className="btnn prebtn textleft"
              >
                Buy
              </Link>
            </div>
            <div className="col">
              <img src={img3blue}></img>
            </div>
          </div>
        </div>
      </section>
       <section className="alphasect">
        <div className="container">
          <div className="columns2">
            <div className="cols alphatext">
              <h3>PVP BATTLES COMING SOON</h3>
              <ul>
                <li>Open to anyone to participate in the BETA mode</li>
                <li>
                  Win in-game items : Swords, shield, potions and much more!
                </li>
              </ul>
            </div>
            <div className="cols alphaimg">
              <img src={bg1img} />
            </div>
          </div>
        </div>
      </section>
      <section className="playsect">
        <div className="container">
          <div className="headings">
            <h2>PLAY</h2>
            <h3>What is coming for Gaia Everworld? </h3>
          </div>
          <div className="columns2">
            <div className="col plybxes">
              <img src={a1} />
              <h3>Gaia Marketplace</h3>
              <a href="https://land.gaiaworld.com" className="btnn prebtn centerbtn">
                Go to Marketplace
              </a>
            </div>
            <div className="col plybxes">
              <img src={b1} />
              <h3>PVP BATTLES</h3>
              <p>Gaia PvP version one goes live in April</p>
            </div>
            <div className="col plybxes">
              <img src={landd1} />
              <h3>Gaia Land</h3>
              <a
                href="https://land.gaiaworld.com/map"
                className="btnn prebtn centerbtn"
              >
                Discover Gaia
              </a>
            </div>
            <div className="col plybxes">
              <img src={d1} />
              <h3>gaia Everworld adventure mode</h3>
              <p>
                A full blown MMORPG adventure game planed to go live in late
                2028
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Ourproducts;
