import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
const Abouttabs = ({}) => {
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  return (
    <section className="about-tabs">
      <div className="container">
        <ul>
          <li className={pathname === "/about" && "active"}>
            <Link to="/about" className="tabsd">
              {t("Gaia EverWorld")}
            </Link>
          </li>
          <li className={pathname === "/gaiatoken" && "active"}>
            <Link to="/gaiatoken" className="tabsd">
              {t("$GAIA Token")}
            </Link>
          </li>
          <li className={pathname === "/gaialand" && "active"}>
            <Link to="/gaialand" className="tabsd">
              {t("Gaia Land")}
            </Link>
          </li>
          <li className={pathname === "/updates" && "active"}>
            <Link to="/updates" className="tabsd">
              {t("Updates")}
            </Link>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Abouttabs;
