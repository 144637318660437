import React from "react";
import riddet from "../Assets/Images/riddet.svg";
import github from "../Assets/Images/github-fill.svg";
import twitter from "../Assets/Images/twitter.svg";
import instagram from "../Assets/Images/instagram.svg";
import youtube from "../Assets/Images/youtube.svg";
import manjado from "../Assets/Images/manajando.svg";
import telegram from "../Assets/Images/telegram.svg";
const Socialicons = ({isFixed}) => {
  return (
    <ul className={`icon ${isFixed ? "sclfixed" : ""}`}>
      <li>
        <a href="https://discord.gg/EGT7c4RVfs" target="_blank">
          <img src={riddet} />
        </a>
      </li>
      <li>
        <a href="https://github.com/gaiaeverworld" target="_blank">
          <img src={github} />
        </a>
      </li>
      <li>
        <a href="https://twitter.com/GaiaEverWorld" target="_blank">
          <img src={twitter} />
        </a>
      </li>
      <li>
        <a href="https://www.instagram.com/gaiaeverworld/" target="_blank">
          <img src={instagram} />
        </a>
      </li>
      <li>
        <a href="https://www.youtube.com/channel/UCU4Zt1YRvo40XQZANcx4nZQ" target="_blank">
          <img src={youtube} />
        </a>
      </li>
      <li>
        <a href="https://medium.com/@gaia-world" target="_blank">
          <img src={manjado} />
        </a>
      </li>
      <li>
        <a href="https://t.me/GaiaEverworld" target="_blank">
          <img src={telegram} />
        </a>
      </li>
    </ul>
  );
};

export default Socialicons;
