import React from "react";
import { useTranslation } from "react-i18next";
import farm1 from "../../Assets/Images/farm1.png";
import farm2 from "../../Assets/Images/farm2.png";

const Rewards = () => {
  const { t } = useTranslation();

  return (
    <section className="playsect rewardsm">
      <div className="container">
        <div className="columns2" style={{justifyContent: "center"}}>
          {/* <div className="col">
            <img src={farm1} alt="" />
            <h3>Farm and yield $gaia</h3>
            <a
              href="https://rewards.gaiaworld.com/farm"
              className="btnn prebtn centerbtn"
              target="_blank"
              rel="noreferrer"
            >
              Go to Farming
            </a>
          </div> */}
          <div className="col">
            <img src={farm2} alt="" />
            <h3>stake and be rewarded</h3>
            <a
              href="https://staking.gaiaworld.com"
              className="btnn prebtn centerbtn"
              target="_blank"
              rel="noreferrer"
            >
              Go to Staking
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Rewards;
