import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import egg1 from "../../Assets/Images/egg1.png";
import b1 from "../../Assets/Images/b1.png";
import L1 from "../../Assets/Images/L1.png";
import d1 from "../../Assets/Images/d1.png";
import b33 from "../../Assets/Images/b33.png";
import b11 from "../../Assets/Images/b11.png";
import b22 from "../../Assets/Images/b22.png";
import blog from "../../Assets/Images/blog.png";
import Abouttabs from "../../Components/abouttabs";
import Innerhero from "../../Components/innerbanner";
import { getMediumPost } from "../../Services/medium.service";

const Gaialand = () => {
  const { t, i18n } = useTranslation();
  const [lastestArticles, setLatestArticles] = useState([]);

  useEffect(() => {
    getAllMediumArticles();
  }, []);

  const getAllMediumArticles = async () => {
    try {
      const articlesFromMedium = await getMediumPost();
      const topThree = articlesFromMedium.slice(0, 3);
      setLatestArticles(topThree);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div id="mainwrapper" className="aboutpage gaialand">
      <Innerhero />
      <Abouttabs />

      <section className="dicsect tokendicsect gaiabg">
        <div className="container">
          <div className="columns2 colwdth">
            <div className="col textpadl">
              <h3>Discover gaia land</h3>
              <p>
                Gaia Everworld is the immersive, multi-region fantasy world in
                which players build their kingdoms, explore the lands, collect,
                breed and battle their Gaia Legionnaires.
              </p>
              <ul className="tbtns">
                <li>
                  
                  <a href="https://land.gaiaworld.com/map" className="btnn prebtn">
                    {t("Discover Gaia")}
                  </a>{" "}
                </li>
                {/* <li><Link to="/" className="btnn secndbtn ">{t("Search Land")}</Link> </li> */}
              </ul>
            </div>
            <div className="col">
              <img src={egg1}></img>
            </div>
          </div>
        </div>
      </section>

      <section className="playsect aboutplay  giland">
        <div className="container">
          <h3>WHAT CAN YOU DO WITH YOUR GAIA LAND?</h3>
          {/* <div className="headings">
            <h2>PLAY</h2>
            <h3>What is coming for Gaia Everworld? </h3>
          </div> */}
          <div className="columns2">
            <div className="col plybxes abtplay">
              <img src={b1} />
              <h3>PVP</h3>
              <p>Create your team and go to war to earn as much $GG possible</p>
            </div>
            <div className="col plybxes abtplay">
              <img src={L1} />
              <h3>LEGION MODE</h3>
              <p>
                Fight alone or join a clan and fight for power in legion mode
              </p>
            </div>
            <div className="col plybxes abtplay">
              <img src={d1} />
              <h3>ADVENTURE MODE</h3>
              <p>
                A full blown MMORPG adventure game full of quests and wars to be
                won
              </p>
            </div>
            <div className="col plybxes abtplay">
              <img src={b33} />
              <h3>MARKETPLACE</h3>
              <p>Buy and sell your in-game items</p>
            </div>
            <div className="col plybxes abtplay">
              <img src={b11} />
              <h3>GAIA LAND</h3>
              <p>Own property on the safezone in Gaia Everworld</p>
            </div>
            <div className="col plybxes abtplay">
              <img src={b22} />
              <h3>GAIA HANDBOOK</h3>
              <p>Learn everything about GAIA in our constant upload handbook</p>
            </div>
          </div>

          <p>
            The Safezone is a region within Gaia Everworld where players can
            meet up, create, experience and monetize content on the land they
            own or rent. The Safezone will consist of 237,732 parcels of land
            called Eever. Each Eever is a unique, non-fungilble and transferable
            digital asset identified by cartesian coordinates (X,Y), which gives
            owners the rights to their piece of the metaverse.
          </p>
        </div>
      </section>

      <section className="zonevideo">
        <div className="container">
          <h3>The Safe Zone</h3>
          <div className="gaig-video">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/pApUzPjn1fM"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          
          </div>
          <p>
            The Safezone is a region within Gaia Everworld where players can
            meet up, create, experience and monetize content on the land they
            own or rent. The Safezone will consist of 237,732 parcels of land
            called Eever. Each Eever is a unique, non-fungilble and transferable
            digital asset identified by cartesian coordinates (X,Y), which gives
            owners the rights to their piece of the metaverse.
          </p>
          <li>
            <Link to="/" className="btnn prebtn">
              {t("Learn More")}
            </Link>{" "}
          </li>
        </div>
      </section>

      <section className="gaiablog">
        <div className="container">
          <h3>Latest NEWS</h3>
          <div className="bloglist columns2">
            {lastestArticles?.map((article, index) => {
              return (
                <div className="blogitem serimg" key={index}>
                  <div className="featuredimg">
                    <img src={article?.thumbnail} />
                  </div>
                  <div className="blogcontent">
                    <h4>{article?.title}</h4>
                    <p
                      dangerouslySetInnerHTML={{ __html: article?.description }}
                    ></p>
                    <Link to="/" className="btnn prebtn">
                      {t("Read More")}
                    </Link>
                  </div>
                </div>
              );
            })}
            <Link to="/updates" className="btnn secndbtn ">
              {t("Load More")}
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Gaialand;
