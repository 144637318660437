import React, { useState } from "react";
 
const Marketplace  = () => {
  return (
    <div className="map " partners>
<section className="comingsoon">
    <div className="container">
        <h1>Coming Soon</h1>
    </div>
</section>
    </div>
  );
};

export default Marketplace ;
