import React, { useState } from "react";
import { Link } from "react-router-dom";

const Termofservice = () => {
  return (
      <div className="privacy-content">      
        <div class="container">
        <div class="heading">
            <h2>TERMS OF SERVICE</h2>
        </div>
        <div class="privacy-content-list">
            <h3>
            {" "}
            <strong>Effective date: </strong> date: 19th November 2021
            </h3>
            <p>
            Welcome to Gaiaworld! This Terms of Service
            <strong>(“Terms”, “Terms of Service”, “Term and Condition”)</strong>
            govern your use of&nbsp;our web pages located at{" "}
            <Link to="/">www.gaiaworld.com</Link> website
            and Gaiaworld mobile application{" "}
            <strong>
                {" "}
                (“Service”, “Website”, “Site”, “App” “we”, “our”, “us”){" "}
            </strong>
            owned and operated by&nbsp;Omnisoft ltd. (“Company”).
            </p>
            <p>
            Our Privacy Policy also governs your use of our Service and explains
            how we collect, safeguard and disclose information that results from
            your use of our web pages. Please read it here{" "}
            <Link to="/privacy-policy">
                https://www.gaiaworld.com/privacy.
            </Link>
            </p>
            <p>
            We provide the Services through our Website and Gaiaworld App. By
            accessing the Services through the Website and/or the App, immediately
            and from your very first access of the Services, you unconditionally
            and irrevocably agree to be bound by these Terms and Conditions, all
            applicable laws and/or regulations and you agree that you are
            responsible for this compliance.{" "}
            </p>
            <p>
            Your agreement with us includes these Terms, Cookie&nbsp;and Privacy
            Policy <strong>(“Agreements”)</strong>&nbsp;. You acknowledge that you
            have read and understood Agreements, and agree to be bound by them.{" "}
            </p>
            <p>
            <strong>
                If you do not agree with (or cannot comply with) Agreements, then
                you may not use the Service. These Terms apply to all visitors,
                users and others who wish to access or use Service.
            </strong>
            </p>
            <p>
            We reserve the right to change these Terms and Conditions. These
            changes are effective immediately upon posting. When changes come into
            effect, the revised Terms and Conditions shall supersede the previous
            version of the Terms and Conditions. You are responsible for regularly
            reviewing these Terms and Conditions.
            </p>
            <p>Thank you for being responsible.</p>
        </div>
        <div class="privacy-content-list">
            <h3>
            <b>1.</b> ACCEPTANCE
            </h3>
            <ul>
            <li>
                {" "}
                <b>1,1</b> By accessing the Website and/or App, you accept to be
                bound by these Terms in full and to comply with all applicable laws.
                Should you disagree with these Terms or any part thereof, you may
                not access the Website and/or the App. As such your continued use of
                the Web and/or the App and/or Services shall be deemed as your
                acceptance of these Terms.
            </li>
            </ul>
        </div>
        <div class="privacy-content-list">
            <h3>
            <b>2.</b> ELIGIBILITY{" "}
            </h3>
            <ul>
            <li>
                {" "}
                <b>2.1.</b> You are at least 13 years of age.
            </li>
            <li>
                {" "}
                <b> 2.2. </b> You use the Website and/or the App and Services
                according to these Terms and all applicable laws and regulations
                determined by the country of residence.
            </li>
            <li>
                {" "}
                <b> 2.3. </b> agree and understand that we may, at any time, and
                without prior notice, revoke and/or cancel your access if you fail
                to meet these criteria or violate any portion of these Terms.
            </li>
            </ul>
        </div>
        <div class="privacy-content-list">
            <h3>
            <b>3.</b> USE OF WEBSITE{" "}
            </h3>
            <ul>
            <li>
                <b>3.1.</b> You may not use the Website to transmit, distribute,
                store or destroy any material or information (i) in violation of any
                applicable law or regulation; (ii) in a manner that will infringe
                the copyright, trademark, trade secret or other intellectual
                property rights of any third party or violate the privacy or other
                personal rights of third parties; or (iii) that is defamatory,
                obscene, threatening, abusive or hateful.
            </li>
            <li>
                <b>3.2.</b> When accessing the Website, you shall be prohibited
                from:
            </li>
            <ul>
                <li>
                <b>3.2.1</b> using any robot, spider, other automatic device or
                manual process to monitor or copy any part of the Website;
                </li>
                <li>
                <b>3.2.2</b> using any device, software or the like to interfere
                or attempt to interfere with the proper functioning of the
                Website;
                </li>
                <li>
                <b>3.2.3.</b> taking any action that imposes an unreasonable or
                disproportionately large data load on the Website infrastructure;
                </li>
                <li>
                <b>3.2.4.</b> copying, reproducing, altering, modifying, creating
                derivative works, or publicly displaying any content from the
                Website without the our prior written consent;
                </li>
                <li>
                <b>3.2.5.</b> reverse assembling, reverse engineering or otherwise
                attempting to discover any source code relating to the Website or
                any tool therein, except to the extent that such activity is
                expressly permitted by applicable law notwithstanding this
                limitation; and
                </li>
                <li>
                <b>3.2.6.</b> attempting to access any area of the Website to
                which access is not authorised.
                </li>
            </ul>
            </ul>
        </div>
        <div class="privacy-content-list">
            <h3>
            {" "}
            <b>4.</b> USE OF APP
            </h3>
            <ul>
            <li>
                <b>4.1</b> You may access Gaiaworld and the Services via our App.{" "}
            </li>
            <li>
                {" "}
                <b>4.2</b> In order to be able to use the App, you shall (i)
                download and install the App on a mobile device which is compatible
                with the App, as well as (ii) have an Internet connection.{" "}
            </li>
            <li>
                <b>4.3</b> You agree that you are solely responsible for meeting the
                aforementioned requirements, which may also (i) bear additional
                charges and fees, and/or (ii) require you to perform software
                updates and/or to comply with the terms and conditions of your
                agreement(s) with your mobile device and telecommunications provider
            </li>
            </ul>
        </div>
        <div class="privacy-content-list">
            <h3>
            <b>5.</b> COMMUNICATIONS
            </h3>
            <ul>
            <li>
                {" "}
                <b>5.1.</b> You agree to subscribe to newsletters, marketing or
                promotional materials and other information we may send.{" "}
            </li>
            <li>
                <b>5.2</b> You may opt out of receiving any, or all, of these
                communications from us by following the unsubscribe link or by
                emailing at{" "}
                <a href="mailto:contact@gaiaworld.com">contact@gaiaworld.com</a>{" "}
            </li>
            </ul>
        </div>
        <div class="privacy-content-list">
            <h3>
            {" "}
            <b>6.</b> REGISTRATION, LOGIN CREDENTIALS, SECURITY
            </h3>
            <ul>
            <li>
                {" "}
                <b>6.1.</b> In order to be able to make use of and access the
                Services, you shall register on the Website and/or on the App by
                providing Company with all required information which includes but
                is not limited to your full name or business name, address and phone
                number. As such, you agree to (i) provide true, accurate, current
                and complete information about yourself as prompted by the sign-up
                process; and (ii) maintain and promptly update the information
                provided during registration to keep it true, accurate, current, and
                complete. Each User account can be accessed by its/his/her holder
                via the Website and/or the App<strong> (“Account”)</strong>.
            </li>
            <li>
                <b>6.2.</b> If you provide any information to Company that is
                untrue, inaccurate, outdated or incomplete, or if we have reasonable
                grounds to suspect that such information is untrue, inaccurate,
                outdated or incomplete, we may terminate your Account with us and
                refuse current or future use of any or all of the Services of
                Gaiaworld
            </li>
            <li>
                <b> 6.3.</b> When registering an Account, if you are registering
                with Company as a customer, you need to choose a password for
                unlimited login sessions<strong> (“Login Credentials”)</strong>.{" "}
            </li>
            <li>
                <b>6.4. </b> You hereby:
            </li>
            <ul>
                <li>
                <b>6.4.1.</b> acknowledge and agree that you are solely
                responsible for maintaining the confidentiality of your Login
                Credentials and for any and all activities that occur under your
                account;
                </li>
                <li>
                {" "}
                <b>6.4.2.</b> agree to notify Company immediately of any
                unauthorized use of your account or of any other breach of
                security. Company shall not be liable for any loss that you may
                incur as a result of any third party using your Login Credentials
                or Account, either with or without your knowledge. However, you
                may be held liable for losses incurred by Company as a result of a
                third party using your Account or Login Credentials.{" "}
                </li>
                <li>
                {" "}
                <b>6.4.3.</b> acknowledge and agree that you may not use anyone
                else's Account at any time, without the express permission of such
                account holder.
                </li>
            </ul>
            </ul>
            <ul>
            <li>
                <b> 6.5 </b> We keep your personal data safe and confidential as
                described in the Privacy Policy.{" "}
            </li>
            <li>
                <b> 6.6 </b> Customers shall be solely responsible for security of
                their account on the Website and/or the App and keep their username
                and password confidential. You shall be solely responsible for any
                actions (and their consequences) made through your account.
            </li>
            <li>
                <b> 6.7 </b> By creating an Account on our Service, you agree to
                subscribe to newsletters, marketing or promotional materials and
                other information we may send. However, you may opt out of receiving
                any, or all, of these communications from us by following the
                unsubscribe link or by emailing at{" "}
                <a href="mailto:contact@gaiaworld.com">contact@gaiaworld.com</a>
            </li>
            </ul>
        </div>
        <div class="privacy-content-list">
            <h3>
            {" "}
            <b>7.</b> ACCOUNTS
            </h3>
            <ul>
            <li>
                <b>7.1.</b> When you create an account with us, you guarantee that
                you are above the age of 13, and that the information you provide us
                is accurate, complete, and current at all times.{" "}
            </li>
            <li>
                {" "}
                <b>7.2.</b> Inaccurate, incomplete, or obsolete information may
                result in the immediate termination of your account on Service
            </li>
            <li>
                {" "}
                <b>7.3.</b> You are responsible for maintaining the confidentiality
                of your account, including but not limited to the restriction of
                access to your computer/mobile phone and/or account.{" "}
            </li>
            <li>
                {" "}
                <b>7.4.</b> You agree to accept responsibility for any and all
                activities or actions that occur under your account.{" "}
            </li>
            <li>
                <b>7.5.</b> You must notify us immediately upon becoming aware of
                any breach of security or unauthorized use of your account.{" "}
            </li>
            <li>
                {" "}
                <b>7.6.</b> We reserve the right to refuse service, terminate
                accounts, remove or edit content, or cancel orders in our sole
                discretion.{" "}
            </li>
            <li>
                {" "}
                <b>7.7.</b> By opening an Account, you expressly represent and
                warrant:
            </li>
            <ul>
                <li>
                <b>7.7.1.</b> You have accepted these Terms of Use; and
                </li>
                <li>
                <b>7.7.2.</b> You are at least 13 years of age and have the full
                capacity to accept these Terms of Use;
                </li>
                <li>
                <b>7.7.3.</b> Have not previously been suspended or removed from
                our Site;{" "}
                </li>
                <li>
                {" "}
                <b>7.7.4.</b> Have full power and authority to enter into this
                legal relationship and by doing so will not violate any other
                legal relationships;
                </li>
                <li>
                {" "}
                <b>7.7.5.</b> Use our Site with your own e-mail and for your own
                benefit and do not act on behalf and/or to the interest of any
                other person;{" "}
                </li>
            </ul>
            </ul>
        </div>
        <div class="privacy-content-list">
            <h3>
            <b>8.</b> TERMINATION{" "}
            </h3>
            <ul>
            <li>
                <b>8.1</b> We may terminate or suspend your account and access to
                Service immediately, without prior notice or liability, under our
                sole discretion, for any reason whatsoever and without limitation,
                including but not limited to a breach of Terms.
            </li>
            <li>
                <b>8.2</b> If you wish to terminate your account, you may simply
                discontinue using Service.
            </li>
            <li>
                <b>8.3</b> All provisions of Terms which by their nature should
                survive termination shall survive termination, including, without
                limitation, warranty disclaimers, indemnity and limitations of
                liability.
            </li>
            <li>
                <b>8.4</b> You may terminate this Agreement with Gaiaworld, and
                close your Account at any time, following settlement of any pending
                transactions.
            </li>
            <li>
                <b>8.5</b> Notwithstanding any other provision of this Agreement,
                User acknowledges and agrees that Gaiaworld has the right to
                immediately terminate the User Account, and terminate access to the
                Service in the following cases:
            </li>
            <ul>
                <li>
                <b>8.5.1</b> there is any suspect or the fact of violation of this
                Agreement, Privacy Policy, or any applicable laws and regulations;
                </li>
                <li>
                <b>8.5.2</b> this User Account and activities of User became the
                subject to a criminal investigation or other pending
                administrative proceeding or litigation;
                </li>
                <li>
                <b>8.5.3</b> Gaiaworld detected unusual and suspicious activity in
                the User Account;
                </li>
                <li>
                <b>8.5.4</b> in case of unauthorized access to the User Account;
                </li>
                <li>
                <b>8.5.5</b> Gaiaworld was required to do so by a court order or
                the order of regulatory/government authority.
                </li>
            </ul>
            </ul>
        </div>
        <div class="privacy-content-list">
            <h3>
            <b>9.</b> PROMOTIONS{" "}
            </h3>
            <ul>
            <li>
                <b>9.1</b> Any contests, sweepstakes or other promotions
                (collectively, “Promotions”) made available through Service may be
                governed by rules that are separate from these Terms of Service.
            </li>
            <li>
                <b>9.2</b> If you participate in any Promotions, please review the
                applicable rules&nbsp;as well as our Privacy Policy.
            </li>
            <li>
                <b>9.3</b> If the rules for a Promotion conflict with these Terms of
                Service, Promotion rules will apply.
            </li>
            </ul>
        </div>
        <div class="privacy-content-list">
            <h3>
            <b>10.</b> SAFETY
            </h3>
            <ul>
            <li>
                <b>10.1</b> User will not (nor assist others to) violate any
                applicable law, contract, intellectual property, or other
                third-party right, and User is solely responsible for its conduct
                while using our Services.{" "}
            </li>
            <li>
                {" "}
                <b>10.2</b> User must not directly, indirectly, or through automated
                or other means:{" "}
            </li>
            <ul>
                <li>
                {" "}
                <b>10.2.1.1.</b> engage in any harassing, threatening,
                intimidating, predatory, or stalking conduct;{" "}
                </li>
                <li>
                <b>10.2.1.2.</b> use or attempt to use another user’s account
                without prior authorization from that user and Gaiaworld;{" "}
                </li>
                <li>
                <b>10.2.1.3.</b> impersonate or register on behalf of any person
                or entity or otherwise misrepresent your affiliation with a person
                or entity, perpetrate fraud, or publish falsehoods or misleading
                statements;{" "}
                </li>
                <li>
                <b>10.2.1.4.</b> collect information of or about other users in
                any impermissible or unauthorized manner;{" "}
                </li>
                <li>
                <b>10.2.1.5.</b> use our Services other than for their intended
                purpose or interfere with, disrupt, negatively affect, or inhibit
                other users;{" "}
                </li>
                <li>
                <b>10.2.1.6.</b>damage, disable, overburden, or impair our
                Services;{" "}
                </li>
                <li>
                <b>10.2.1.7.</b> send, distribute, or post spam, unsolicited
                electronic communications, chain letters, pyramid schemes, or
                illegal or impermissible communications;{" "}
                </li>
                <li>
                <b>10.2.1.8.</b> post, upload, or share any content which is
                unlawful, libelous, defamatory, obscene, pornographic, indecent,
                lewd, suggestive, harassing, threatening, invasive of privacy or
                publicity rights, abusive, inflammatory, fraudulent, or is in our
                sole judgment objectionable;{" "}
                </li>
                <li>
                <b>10.2.1.9.</b> encourage or provide instructions for a criminal
                offense;{" "}
                </li>
                <li>
                <b>10.2.1.10.</b> distribute any viruses, corrupted data, or other
                harmful, disruptive, or destructive files or content;{" "}
                </li>
                <li>
                <b>10.2.1.11.</b> bypass, ignore, or circumvent instructions in
                our robots.txt file or any measures we employ to prevent or limit
                access to any part of our Services, including content-filtering
                techniques; or{" "}
                </li>
                <li>
                <b>10.2.1.12.</b> expose Gaiaworld or others to any type of harm
                or liability.
                </li>
            </ul>
            </ul>
        </div>
        <div class="privacy-content-list">
            <h3>
            <b>11.</b> NO USE BY MINORS
            </h3>
            <ul>
            <li>
                <b>11.1</b> Service is intended only for access and use by
                individuals at least (13) years old. By accessing or using any of
                the Company, you warrant and represent that you are at least (13)
                years of age and with the full authority, right, and capacity to
                enter into this agreement and abide by all of the terms and
                conditions of Terms. If you are not at least (13) years old, you are
                prohibited from both the access and usage of Service.
            </li>
            </ul>
        </div>
        <div class="privacy-content-list">
            <h3>
            <b>12.</b> INTELLECTUAL PROPERTY{" "}
            </h3>
            <ul>
            <li>
                <b>12.1.</b> Service and its original content features and
                functionality are and will remain the exclusive property
                of&nbsp;Gaiaworld and its licensors.
            </li>
            <li>
                {" "}
                <b> 12.2.</b> Service is protected by copyright, trademark, and
                other laws of the British Virgin Islands.
            </li>
            <li>
                <b>12.3.</b> Our trademarks and trade dress may not be used in
                connection with any product or service without the prior written
                consent of&nbsp;Gaiaworld.
            </li>
            <li>
                <b>12.4.</b> All code, text, software, scripts, graphics, files,
                photos, images, logos, and materials contained on this website, our
                applications, or within the services, are the sole property
                of&nbsp;Gaiaworld.
            </li>
            <li>
                <b>12.5.</b> Unauthorized use of any materials contained on this
                Website and/or the App, our applications or within the Service may
                violate copyright laws, trademark laws, the laws of privacy and
                publicity, and/or other regulations and statutes. If you believe
                that any of the materials infringe on any third party's rights,
                please contact&nbsp;Gaiaworld immediately at:{" "}
                <a href="mailto:contact@gaiaworld.com">contact@gaiaworld.com</a>
            </li>
            <li>
                <b>12.6.</b> All content, trademarks, data, information or
                information contained in any materials, or documents used in
                relation to the Company and the Services, including but not limited
                to any and all copyrighted works, software, databases, text,
                graphics, icons, designs, logos, graphics, hyperlinks, domain names,
                information and agreements<strong>(“Content”)</strong> , are the
                exclusive property of or are licensed to the Company.
            </li>
            <li>
                <b>12.7.</b> You may not reproduce, distribute or copy the Content
                by any means, whether electronically or not, without the Company’s
                prior written permission.
            </li>
            <li>
                <b>12.8.</b> Any and all intellectual property rights in the
                Content, Services and the Company or otherwise developed by or on
                behalf of the Company, including all patents, rights in inventions,
                rights in designs, trademarks, trade and business names and all
                associated goodwill, rights to sue for passing off or for unlawful
                competition, copyright, moral rights and related rights, rights in
                databases, topography rights, domain names, rights in information
                (including know how and trade secrets) and all other similar or
                equivalent rights existing in the Content, Services and the Company
                or otherwise developed by or on behalf of the Company, now or in the
                future in any part of the world, in each case whether registered or
                unregistered and including all applications for, and renewals or
                extensions of, such rights for their full term (“Intellectual
                Property”), vests in the Company, its licensors or suppliers, as the
                case may be, and all rights not expressly granted by the Company to
                you are reserved by the Company.
            </li>
            <li>
                <b>12.9.</b> Save as expressly set out herein and in the Contracts,
                you shall not acquire any right, title or interest in the Company’s
                Intellectual Property.
            </li>
            <li>
                {" "}
                <b>12.10.</b> You may not use the Company’s Intellectual Property in
                a manner which may (i) place the Company’s Intellectual Property at
                risk of losing value, and (ii) cause reputational damage to the
                Company.
            </li>
            </ul>
        </div>
        <div class="privacy-content-list">
            <h3>
            <b>13.</b> LICENSES
            </h3>
            <ul>
            <li>
                <b>13.1.</b>
                <strong>Your Rights.</strong>&nbsp;Gaiaworld does not claim
                ownership of the information that you submit for your Gaiaworld
                account or through our Services. You must have the necessary rights
                to such information that you submit for your Gaiaworld account or
                through our Services and the right to grant the rights and licenses
                in our Terms.
            </li>
            <li>
                <b>13.2.</b>
                <strong>Gaiaworld’s Rights.</strong>&nbsp;We own all copyrights,
                trademarks, domains, logos, trade dress, trade secrets, patents, and
                other intellectual property rights associated with our Services. You
                may not use our copyrights, trademarks, domains, logos, trade dress,
                patents, and other intellectual property rights unless you have our
                express permission
            </li>
            <li>
                <b>13.3.</b>
                <strong>Your License to Gaiaworld.</strong> &nbsp;In order to
                operate and provide our Services, you grant Gaiaworld a worldwide,
                non-exclusive, royalty-free, sublicensable, and transferable license
                to use, reproduce, distribute, create derivative works of, display,
                and perform the information (including the content) that you upload,
                submit, store, send, or receive on or through our Services. The
                rights you grant in this license are for the limited purpose of
                operating and providing our Services (such as to allow us to display
                your profile picture and status message, transmit your messages,
                store your undelivered messages on our servers for up to 30 days as
                we try to deliver them, and otherwise as described in our Privacy
                Policy).
            </li>
            <li>
                <b>13.4.</b>
                <strong>Gaiaworld’s License to You.</strong>&nbsp;We grant you a
                limited, revocable, non-exclusive, non-sublicensable, and
                non-transferable license to use our Services, subject to and in
                accordance with our Terms. This license is for the sole purpose of
                enabling you to use our Services, in the manner permitted by our
                Terms. No licenses or rights are granted to you by implication or
                otherwise, except for the licenses and rights expressly granted to
                you.
            </li>
            </ul>
        </div>
        <div class="privacy-content-list">
            <h3>
            <b>14.</b> ERROR REPORTING AND FEEDBACK{" "}
            </h3>
            <ul>
            <li>
                <b>14.1</b> You may provide us&nbsp;either directly at&nbsp;
                <a href="mailto:contact@gaiaworld.com">contact@gaiaworld.com</a> or
                via third party sites and tools&nbsp;with information and feedback
                concerning errors, suggestions for improvements, ideas, problems,
                complaints, and other matters related to our Service (“Feedback”).
            </li>
            <li>
                <b>14.2.</b> You acknowledge and agree that: (i) you shall not
                retain, acquire or assert any intellectual property right or other
                right, title or interest in or to the Feedback; (ii) the Company may
                have development ideas similar to the Feedback; (iii) Feedback does
                not contain confidential information or proprietary information from
                you or any third party; and (iv) the Company is not under any
                obligation of confidentiality with respect to the Feedback.
            </li>
            <li>
                <b>14.3.</b> In the event the transfer of the ownership to the
                Feedback is not possible due to applicable mandatory laws, you grant
                the Company and its affiliates an exclusive, transferable,
                irrevocable, free-of-charge, sub-licensable, unlimited and perpetual
                right to use (including copy, modify, create derivative works,
                publish, distribute and commercialize) Feedback in any manner and
                for any purpose.
            </li>
            <li>
                {" "}
                <b>14.4.</b> We shall not be liable for any typo or any information
                being incorrect in this Agreement, in case you have discovered such
                type or mistake in wording of this Agreement you may inform us about
                such mistake directly at&nbsp;
                <a href="mailto:contact@gaiaworld.com">contact@gaiaworld.com</a>
            </li>
            </ul>
        </div>
        <div class="privacy-content-list">
            <h3>
            <b>15.</b>LINKS TO OTHER WEB SITES{" "}
            </h3>
            <ul>
            <li>
                <b>15.1</b> Our Service may contain links to third party web sites
                or services that are not owned or controlled by&nbsp;Gaiaworld.
            </li>
            <li>
                <b>15.2</b> Gaiaworld has no control over, and assumes no
                responsibility for the content, privacy policies, or practices of
                any third party web sites or services. We do not warrant the
                offerings of any of these entities/individuals or their websites.
            </li>
            <li>
                <b>15.3</b> YOU ACKNOWLEDGE AND AGREE THAT WE&nbsp;SHALL NOT BE
                RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR
                LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF
                OR RELIANCE ON ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR
                THROUGH ANY SUCH THIRD PARTY WEB SITES OR SERVICES.
            </li>
            <li>
                <b>15.4</b> WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND
                PRIVACY POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU
                VISIT.
            </li>
            </ul>
        </div>
        <div class="privacy-content-list">
            <h3>
            <b>16.</b> DISCLAIMER OF WARRANTY{" "}
            </h3>
            <ul>
            <li>
                <b>16.1</b> THESE SERVICES ARE PROVIDED BY THE COMPANY ON AN “AS IS”
                AND “AS AVAILABLE” BASIS. THE COMPANY MAKES NO REPRESENTATIONS OR
                WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF
                THEIR SERVICES, OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED
                THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR
                CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE
                RISK.
            </li>
            <li>
                <b>16.2</b> NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE
                COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
                COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR
                AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING,
                NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY
                REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY
                SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE,
                RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE
                CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES IT AVAILABLE
                ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES
                OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL
                OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
            </li>
            <li>
                <b>16.3</b> THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND,
                WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT
                NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT,
                AND FITNESS FOR PARTICULAR PURPOSE.
            </li>
            <li>
                <b>16.4</b> THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH
                CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.{" "}
            </li>
            </ul>
        </div>
        <div class="privacy-content-list">
            <h3>
            <b>17.</b> LIMITATION OF LIABILITY{" "}
            </h3>
            <ul>
            <li>
                <b>17.1</b> EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR
                OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY
                INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE,
                HOWEVER IT ARISES (INCLUDING ATTORNEYS' FEES AND ALL RELATED COSTS
                AND EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON
                APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS
                INSTITUTED), WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER
                TORTIOUS ACTION, OR ARISING OUT OF OR IN CONNECTION WITH THIS
                AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM FOR PERSONAL
                INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY
                VIOLATION BY YOU OF ANY LAWS, STATUTES, RULES, OR REGULATIONS, EVEN
                IF THE COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF
                SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY
                FOUND ON THE PART OF THE COMPANY, IT WILL BE LIMITED TO THE AMOUNT
                PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES
                WILL THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES.
            </li>
            </ul>
        </div>
        <div class="privacy-content-list">
            <h3>18. GENERAL UNDERTAKING</h3>
            <ul>
            <li>
                <b>18.1 </b>When you use our Service you accept that you will not:
            </li>
            <ul>
                <li>
                <b>18.1.1. </b> breach any applicable laws or regulations that
                apply;
                </li>
                <li>
                <b>18.1.2. </b> breach any of the Terms;
                </li>
                <li>
                <b>18.1.3. </b> post or take any threatening, abusive, defamatory,
                obscene, inappropriate action or post any indecent material, or
                material or action that is invasive of another’s privacy
                (including but not limited to other User and our employees);{" "}
                </li>
                <li>
                <b>18.1.4. </b> share or otherwise upload or communicate any
                misleading or false information or messages of any kind;{" "}
                </li>
                <li>
                <b>18.1.5. </b> use our Service to intentionally deceive other
                Users;{" "}
                </li>
                <li>
                <b>18.1.6. </b> use our Service to stalk or otherwise harass
                another User;
                </li>
                <li>
                <b>18.1.7. </b> use our Service if we have suspended or banned you
                from using it.{" "}
                </li>
                <li>
                <b>18.1.8. </b> infringe the Intellectual Property Rights of any
                third-party;
                </li>
                <li>
                <b>18.1.9. </b> distribute any viruses or any other technologies
                that may harm our Service or its Users
                </li>
                <li>
                <b>18.1.10.</b> interfere with the property working of our Service
                </li>
                <li>
                <b>18.1.11.</b> copy modify, or distribute any other person’s
                Content without their consent use any robot, spider, scraper or
                other automated means to access our Service and/or collect Content
                or data for any purpose;
                </li>
                <li>
                <b>18.1.12.</b> collect information about other Users, including
                email addresses, postal addresses, phone numbers, credit card or
                banking information or similar information without their explicit
                consent;
                </li>
                <li>
                <b>18.1.13.</b> copy, modify or distribute rights or Content or
                collect data from our Service, applications or tools or otherwise
                infringe our or User’s Intellectual Property Rights;
                </li>
                <li>
                <b>18.1.14.</b> bypass, disable or attempt to disable any security
                measures used in connection with our Service;
                </li>
                <li>
                <b>18.1.15.</b> collect any data (including personal data) from
                our Service other than in accordance with these Terms and
                applicable laws;
                </li>
                <li>
                <b>18.1.16.</b> sell any counterfeit Items or otherwise infringe
                the copyright, trademark or other rights of third parties;
                </li>
                <li>
                <b>18.1.17.</b> write and post reviews that are anything other
                than true and accurate to the best of your knowledge; or{" "}
                </li>
                <li>
                <b>18.1.18.</b> impersonate any person or misrepresent your
                affiliation with a person or entity.{" "}
                </li>
            </ul>
            </ul>
        </div>
        <div class="privacy-content-list">
            <h3>19. ACCESS AND USE </h3>
            <ul>
            <li>
                <b>19.1 </b>You must only use the Service in accordance with these
                Terms and any applicable law.{" "}
            </li>
            <li>
                <b>19.2 </b> You must not (or attempt to):{" "}
            </li>
            <ul>
                <li>
                <b>19.2.1. </b>Interfere with or disrupt the Service or the server
                or networks that host the Site;{" "}
                </li>
                <li>
                <b>19.2.2. </b>Use data mining, robots, screen scraping or similar
                data gathering and extraction tools on the Site; or{" "}
                </li>
                <li>
                <b>19.2.3. </b>Interfere with security-related or other features
                of the Service.
                </li>
            </ul>
            </ul>
            <ul>
            <li>
                <b>19.3.</b> We do not warrant that the Service will be available at
                all times and without disruption and we provide no warranties in
                relation to the content of any other website linked to or from our
                Service.{" "}
            </li>
            <li>
                <b>19.4.</b> You must not link to our Service or any part of our
                Service in a way that damages or takes advantage of our reputation,
                including but not limited to:{" "}
            </li>
            <ul>
                <li>
                <b>19.4.1.</b> In a way to suggest or imply that you have any kind
                of association and affiliation with us, or approval and
                endorsement from us when there is none; or{" "}
                </li>
                <li>
                <b>19.4.2.</b> In a way that is illegal or unfair.{" "}
                </li>
            </ul>
            </ul>
        </div>
        <div class="privacy-content-list">
            <h3>20. PROHIBITED USES </h3>
            <ul>
            <li>
                <b>20.1.</b> You may use Service only for lawful purposes and in
                accordance with Terms.{" "}
            </li>
            <li>
                <b>20.2.</b> You agree not to use Service:
            </li>
            <ul>
                <li>
                <b>20.2.1.</b> In any way that violates any applicable national or
                international law or regulation.
                </li>
                <li>
                <b>20.2.2.</b> For the purpose of exploiting, harming, or
                attempting to exploit or harm minors in any way by exposing them
                to inappropriate content or otherwise.
                </li>
                <li>
                <b>20.2.3.</b> To transmit, or procure the sending of, any
                advertising or promotional material, including any “junk mail”,
                “chain letter,” “spam,” or any other similar solicitation.
                </li>
                <li>
                <b>20.2.4.</b> To impersonate or attempt to impersonate us, our
                employee, another user, or any other person or entity.
                </li>
                <li>
                <b>20.2.5.</b> In any way that infringes upon the rights of
                others, or in any way is illegal, threatening, fraudulent, or
                harmful, or in connection with any unlawful, illegal, fraudulent,
                or harmful purpose or activity.
                </li>
                <li>
                <b>20.2.6.</b> To engage in any other conduct that restricts or
                inhibits anyone’s use or enjoyment of Service, or which, as
                determined by us, may harm or offend the us or users of Service or
                expose them to liability.
                </li>
            </ul>
            </ul>
            <ul>
            <li>
                <b>20.3 </b>Additionally, you agree not to:
            </li>
            <ul>
                <li>
                <b>20.3.1. </b> Use Service in any manner that could disable,
                overburden, damage, or impair Service or interfere with any other
                party’s use of Service, including their ability to engage in real
                time activities through Service.
                </li>
                <li>
                <b>20.3.2. </b> Use any robot, spider, or other automatic device,
                process, or means to access Service for any purpose, including
                monitoring or copying any of the material on Service.
                </li>
                <li>
                <b> 20.3.3.</b> Use any manual process to monitor or copy any of
                the material on Service or for any other unauthorized purpose
                without our prior written consent.
                </li>
                <li>
                <b>20.3.4. </b> Use any device, software, or routine that
                interferes with the proper working of Service.
                </li>
                <li>
                <b>20.3.5. </b> Introduce any viruses, trojan horses, worms, logic
                bombs, or other material which is malicious or technologically
                harmful.
                </li>
                <li>
                <b>20.3.6. </b> Attempt to gain unauthorized access to, interfere
                with, damage, or disrupt any parts of Service, the server on which
                Service is stored, or any server, computer, or database connected
                to Service.{" "}
                </li>
                <li>
                <b>20.3.7. </b> Attack Service via a denial-of-service attack or a
                distributed denial-of-service attack.
                </li>
                <li>
                <b>20.3.8. </b> Take any action that may damage or falsify our
                rating.
                </li>
                <li>
                <b>20.3.9. </b> Otherwise attempt to interfere with the proper
                working of Service.
                </li>
            </ul>
            </ul>
        </div>
        <div class="privacy-content-list">
            <h3>21.GOVERNING LAW</h3>
            <ul>
            <li>
                <b>21.1</b> These Terms shall be governed and construed in
                accordance with the laws of the British Virgin Islands without
                regard to its conflict of law provisions.
            </li>
            <li>
                <b>21.2</b> Our failure to enforce any right or provision of these
                Terms will not be considered a waiver of those rights.
            </li>
            <li>
                <b>21.3</b> If any provision of these Terms is held to be invalid or
                unenforceable by a court, the remaining provisions of these Terms
                will remain in effect.{" "}
            </li>
            <li>
                <b>21.4</b> These Terms constitute the entire agreement between us
                regarding our Service and supersede and replace any prior agreements
                we might have had between us regarding Service.
            </li>
            <li>
                <b>21.5</b> Each party irrevocably agrees that the courts of the
                British Virgin Islands shall have exclusive jurisdiction to settle
                any dispute or claim arising out of or in connection with this
                agreement or its subject matter or formation (including
                non-contractual disputes or claims).
            </li>
            <li>
                <b>21.6</b> Notwithstanding the specified agreement on jurisdiction,
                you and the Company shall, if any dispute arises, attempt to settle
                it by mutual negotiations.
            </li>
            </ul>
        </div>
        <div class="privacy-content-list">
            <h3>22. CHANGES TO SERVICE </h3>
            <ul>
            <li>
                <b>22.1.</b> We reserve the right to withdraw or amend our Service,
                and any service or material we provide via Service, in our sole
                discretion without notice.
            </li>
            <li>
                <b>22.2.</b> We will not be liable if for any reason all or any part
                of Service is unavailable at any time or for any period.
            </li>
            <li>
                <b>22.3.</b> From time to time, we may restrict access to some parts
                of Service, or the entire Service, to users, including registered
                users.{" "}
            </li>
            </ul>
        </div>
        <div class="privacy-content-list">
            <h3>23. AMENDMENTS TO TERMS </h3>
            <ul>
            <li>
                <b>23.1.</b> We may amend Terms at any time by posting the amended
                terms on this site. It is your responsibility to review these Terms
                periodically.
            </li>
            <li>
                <b>23.2.</b> Your continued use of the Website and/or the App
                following the posting of revised Terms means that you accept and
                agree to the changes.
            </li>
            <li>
                <b>23.3.</b> You are expected to check this page frequently so you
                are aware of any changes, as they are binding on you.
            </li>
            <li>
                <b>23.4.</b> By continuing to access or use our Service after any
                revisions become effective, you agree to be bound by the revised
                terms.{" "}
            </li>
            <li>
                <b>23.5.</b> If you do not agree to the new terms, you are no longer
                authorized to use Service.{" "}
            </li>
            </ul>
        </div>
        <div class="privacy-content-list">
            <h3>24. INDEMNITY</h3>
            <ul>
            <li>
                <b>24.1</b> You agree to indemnify, defend and hold harmless us and
                our related entities, affiliates, and our and their respective
                officers, agents, and employees, harmless from and against any and
                all claims demands (actual, special and consequential) of every kind
                and nature, known and unknown, including reasonable legal fees, made
                by any third party due to or arising out of your breach of these
                terms or your breach of any law or the right of third party.
            </li>
            </ul>
        </div>
        <div class="privacy-content-list">
            <h3>25. WAIVER AND SEVERABILITY </h3>
            <ul>
            <li>
                <b>25.1</b> No waiver by the Company of any term or condition set
                forth in Terms shall be deemed a further or continuing waiver of
                such term or condition or a waiver of any other term or condition,
                and any failure of the Company to assert a right or provision under
                Terms shall not constitute a waiver of such right or provision.
            </li>
            <li>
                <b>25.2.</b> If any provision of Terms is held by a court or other
                tribunal of competent jurisdiction to be invalid, illegal or
                unenforceable for any reason, such provision shall be eliminated or
                limited to the minimum extent such that the remaining provisions of
                Terms will continue in full force and effect.
            </li>
            </ul>
        </div>
        <div class="privacy-content-list">
            <h3>26. ACKNOWLEDGEMENT </h3>
            <ul>
            <li>
                <b>26.1.</b> BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU
                ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO
                BE BOUND BY THEM.
            </li>
            </ul>
        </div>
        <div class="privacy-content-list">
            <h3>27. CONTACT US </h3>
            <ul>
            <li>
                <b>27.1</b> For more information about our Term and Conditions, if
                you have questions, or if you would like to make a complaint, please
                send your feedback, comments, requests for technical support: by
                email:{" "}
                <a href="mailto:contact@gaiaworld.com">contact@gaiaworld.com</a>
            </li>
            </ul>
        </div>
        </div>
    </div>
  );
};

export default Termofservice;
