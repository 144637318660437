import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import gtokenb from "../../Assets/Images/gtokenb.png";
import logo5 from "../../Assets/Images/logo5.png";
import logo4 from "../../Assets/Images/logo4.png";
import logo2 from "../../Assets/Images/logo2.png";
import logo3 from "../../Assets/Images/logo3.png";
import logo1 from "../../Assets/Images/logo1.png";
import gaiadiagram from "../../Assets/Images/gaiadiagram.png";
import Abouttabs from "../../Components/abouttabs";
import Innerhero from "../../Components/innerbanner";

const Abouttoken = ({openModal}) => {
  const { t, i18n } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);
  
  const width = document.body.clientWidth;

  useEffect(() => {
    if (width < 600) {
      setIsMobile(true);
    }
    console.log("width changing ===>", width);
  }, [width])

  return (
    <div id="mainwrapper" className="aboutpage ">
      <Innerhero />
      <Abouttabs />

      {/* <section className="dicsect tokendicsect">
        <div className="container">
          <div className="columns2 colwdth">
            <div className="col textpadl">
              <h3>Discover gaia land</h3>
              <p>
                Gaia Everworld is the immersive, multi-region fantasy world in
                which players build their kingdoms, explore the lands, collect,
                breed and battle their Gaia Legionnaires.
              </p>
              <ul className="tbtns">
              <li><a target="_blank" href="https://docs.google.com/forms/d/1OgTunX2WshvmOlQLSuBDkTjLDHxJNGHYClJDOCXxrGQ/viewform?edit_requested=true" className="btnn prebtn">{t("Apply Whitelist")}</a> </li>
                    <li><Link to="/" className="btnn secndbtn ">{t("Search Land")}</Link> </li>
              </ul>
            </div>
            <div className="col">
              <img src={gtokenb}></img>
            </div>
          </div>
        </div>
      </section> */}

      <section className="buytoken">
        <div className="container">
          <div className="buygaia">
            <h3>Buy $gaia Token</h3>
            <p>Available on</p>
            <div className="tokenrow">
              <div className="tokenitem">
              <a href="https://quickswap.exchange/#/swap?inputCurrency=0x723b17718289a91af252d616de2c77944962d122&outputCurrency=0x8f3cf7ad23cd3cadbd9735aff958023239c6a063" target="_blank">
              <img src={logo4} />
                  </a>
               
              </div>
              <div className="tokenitem">
              <a href="https://analytics-polygon.sushi.com/tokens/0x723b17718289a91af252d616de2c77944962d122" target="_blank">
              <img src={logo2} />
                  </a>
                
              </div>

              <div className="tokenitem">
              <a href="https://pancakeswap.finance/" target="_blank">
              <img src={logo3} />
                  </a>
               
              </div>
              <div className="tokenitem">
                <a href="https://www.gate.io/trade/GAIA_USDT" target="_blank">
                <img src={logo1} />
                </a>
                
              </div>
              <div className="tokenitem">
                <a href="https://www.mexc.com/exchange/GAIA_USDT" target="_blank">
                <img src={logo5} />
                </a>
                
              </div>
            </div>
          </div>

          <div className="buycontent">
            <h3>$GAIA - ERC20 - Governance and Marketplace Currency</h3>
            <p>
            The $GAIA holders can stake it to receive $GAIA staking rewards. Holders will have voting rights on proposals for the future of the game. $GAIA will also be the accepted currency in the EverWorld Marketplace. There is a limited supply of 500,000,000 $GAIA.
            </p>
            <div className="buylist">
              <ul className="buyitem">
                <li>Angel Round:</li>
                <li>2% - 10,000,000.00</li>
              </ul>
              <ul className="buyitem">
                <li>Seed Round: </li>
                <li>4% - 20,000,000.00</li>
              </ul>
              <ul className="buyitem">
                <li>Strategic Sale:</li>
                <li>6% - 30,000,000.00</li>
              </ul>
              <ul className="buyitem">
                <li>Play to Earn:</li>
                <li>25% - 125,000,000.00</li>
              </ul>
              <ul className="buyitem">
                <li>Staking Rewards:</li>
                <li>25% - 125,000,000.00</li>
              </ul>
              <ul className="buyitem">
                <li>Ecosystem:</li>
                <li>11% - 55,000,000.00</li>
              </ul>
              <ul className="buyitem">
                <li>Radicle Gaming (Core Team) :</li>
                <li>20% - 100,000,000.00</li>
              </ul>
              <ul className="buyitem">
                <li>Public Sale:</li>
                <li>1% - 5,000,000.00</li>
              </ul>
              <ul className="buyitem">
                <li>Advisors:</li>
                <li> 6% - 30,000,000.00</li>
              </ul>
            </div>
            <p>
            25% of the total $GAIA supply is allocated as play-to-earn incentives to attract a vast range of players to the EverWorld ecosystem. Players can play for fun and earn at the same time. The most active and successful users will earn greater portions of the rewards and thus own more of the game they love to play.
<br></br><b>Players will be able to earn within the EverWorld ecosystem by</b>
            </p>
            <div className="buydetail">
              <ul>
                <li>
                Competing in the arena and winning battles
                </li>
                <li>
                Staking
                </li>
                <li>
                Participating in DAO votes
                </li>
                <li>
                Winning multi-round tournaments
                </li>
                <li>
                Breeding their Gaia
                </li>
                <li>
                Completing challenges and being top of leaderboards
                </li>
               
              </ul>
              <p>
                Tokens earned through the play-to-earn mechanism will be tradable right away, so players could potentially see their game time giving them purchasing power in the real world within the same day.
                </p>
                <p><b>Utility</b> In game marketplace currency. The Marketplace will be the go to resource to purchase Land, resources, weapons and outfits to customize avatars, and to purchase Gaia's to create your legion.</p>
                <p>Breeding fees.</p>
                <p>Land owners will be able to customize their Land which can then be monetised by creating quests, renting or selling the Land or features on it. All the transactions will be done using the $GAIA token.</p>
                <p>$GAIA holders will be able to present and vote on proposals via a DAO. Proposals will be made to the DAO where holders will vote with a majority vote winning.</p>

            </div>
          </div>
        </div>
      </section>

      <section className="gaiamap">
        <div className="container">
          <h3>Gaia Gold - ERC20 - Breeding and Upgrade Currency</h3>
          <p>
          The in-game token GAIA GOLD ($GG) is an ERC20 token that players can earn through play and then use to breed their Gaia. The $GG is earned by playing the game and winning battles. This utility token is uncapped to support the growth of the game. The $GG used for breeding is burnt permanently and removed from the supply, limiting inflation.
          </p>
          <p>
          Gaia Gold is the main token that players receive – the one mostly used to cover the cost of breeding their Gaia. $GG can also be used to upgrade their avatar and Gaia skill levels. Once the tokens are spent on upgrades and breeding, they are burnt from the total supply. The rewards players can earn will be determined by the economics of the game at the time of play. ‌

          </p>
        </div>
        <div className="mapimages">
          <a href="https://coinmarketcap.com/currencies/gaia-everworld/" target="_blank">
          <img src={gaiadiagram} />
          </a>
        </div>
        {isMobile && (
          <button className="modal-btn" onClick={openModal}>Click image to zoom</button>
        )}
      </section>
    </div>
  );
};

export default Abouttoken;
