import "./App.css";
import { useState, Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./screens/Home";
import About from "./screens/About";
import Gaialand from "./screens/Gaialand";
import Gaiatoken from "./screens/Gaiatoken";
import Rewards from "./screens/Rewards";
import Updates from "./screens/Updates";
import Ourproducts from "./screens/Ourproducts";
import Termofservice from "./screens/Termofservice";
import Map from "./screens/Map";
import Marketplace from "./screens/Market";
import Privacy from "./screens/Privacy";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Sidebar from "./Components/Sidebar";
import Loader from "./Components/Loader";
import ScrollToTop from "./Components/ScrollToTop"; 
import gaiadiagram from "./Assets/Images/gaiadiagramr.svg";
import close2 from "./Assets/Images/close2.png";
import "./i18n";
import(/* webpackPreload: true */ './Assets/fonts/NotoSans-BoldItalic.woff2');
import(/* webpackPreload: true */ './Assets/fonts/NotoSans-BoldItalic.woff');
import(/* webpackPreload: true */ './Assets/fonts/NotoSans.woff2');
import(/* webpackPreload: true */ './Assets/fonts/NotoSans.woff');
import(/* webpackPreload: true */ './Assets/fonts/NotoSans-Italic.woff2');
import(/* webpackPreload: true */ './Assets/fonts/NotoSans-Italic.woff');
import(/* webpackPreload: true */ './Assets/fonts/NotoSans-Bold.woff2');
import(/* webpackPreload: true */ './Assets/fonts/NotoSans-Bold.woff');
import(/* webpackPreload: true */ './Assets/fonts/Impact.woff2');
import(/* webpackPreload: true */ './Assets/fonts/Impact.woff');


function App() {
  const [darkMode, setDarkMode] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 3000)
  }, [])

  return (
    <div className="wrapper">
      <Suspense fallback={null}>
        <BrowserRouter>
          <ScrollToTop>
            {!isLoaded && (
              <Loader />
            )}
            <Header darkMode={darkMode} setDarkMode={setDarkMode} />
            <Sidebar />
            <div className="maindiv">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/our-products" element={<Ourproducts />} />
                <Route path="/gaialand" element={<Gaialand />} />
                <Route path="/gaiatoken" element={<Gaiatoken openModal={openModal} />} />
                <Route path="/updates" element={<Updates />} />
                <Route path="/rewards" element={<Rewards />} />
                <Route path="/term_of_service" element={<Termofservice />} />
                <Route path="/privacy-policy" element={<Privacy />} />
                <Route path="/map" element={<Map />} />
                <Route path="/marketplace" element={<Marketplace />} />
              </Routes>
              <Footer darkMode={darkMode} />
            </div>
          </ScrollToTop>
        </BrowserRouter>
      </Suspense>
      {isOpen && (
        <>
          <div className="fxdimg">
            <button onClick={closeModal}>
              <img src={close2} alt="clse" />
            </button>
            <a href="https://coinmarketcap.com/currencies/gaia-everworld/" target="_blank" rel="noreferrer">
            <img
              src={gaiadiagram}
              alt="popup"
            />
            </a>
          </div>
          <div className="overlay" onClick={closeModal}></div>
        </>
      )}
    </div>
  );
}

export default App;
