import React from 'react'
import logo from "../../Assets/Images/Logo.svg";

const Loader = () => {
  return (
    <div className='loader'>
        <img src={logo} alt="Logo" />
    </div>
  )
}

export default Loader