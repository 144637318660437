/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../Assets/Images/footerlogo.svg";
import Socialicons from "../socialicons";
import { useTranslation } from "react-i18next";
import radicle from "../../Assets/Images/radicle.svg";

const Footer = ({ darkMode }) => {
  const { t } = useTranslation();
  const {pathname} = useLocation();

  if (pathname === '/rewards') {
    return null;
  }

  return (
    <footer>
      <div className="container">
        <div className="logosec">
          <img src={logo} alt="" />
          <p className="branddetail">
            {t(
              "Gaia Everworld is the immersive, multi-region fantasy world in which players build their kingdoms, explore the lands, collect, breed and battle their Gaia Legionnaires."
            )}
          </p>
          <p>Copyright © {new Date().getFullYear()} Gaia Everworld</p>
        </div>
        <div className="logsect">
          <h2>{t("Links")}</h2>
          <div className="clicklink">
            <ul>
              <li>
                <Link to="/">{t("Home")}</Link>
              </li>
              <li>
                <Link to="/about">{t("About")}</Link>
              </li>
              <li>
                <Link to="/our-products">{t("Game Modes")}</Link>
              </li>
              <li>
                <a href="https://land.gaiaworld.com">{t("Marketplace")}</a>
              </li>
            </ul>
            <ul>
              <li>
                <Link to="/map">{t("Map")}</Link>
              </li>
              <li>
                <a target="_blank" href="https://rewards.gaiaworld.com/" rel="noreferrer">{t("Rewards")}</a>
              </li>
              <li>
                <a target="_blank" href="https://gaiaeliteclub.com/mint" rel="noreferrer">{t("Gaiaeliteclub")}</a>
              </li>
            </ul>
            <ul>
              <li>
                <Link to="/privacy-policy">{t("Privacy Notice")}</Link>
              </li>
              <li>
                <Link to="/term_of_service">{t("Terms of Service")}</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="soicallinks">
          <div className="soicalicon">
            <h2>{t("Subscribe For The Latest News")}</h2>
            <form className="newsleter">
              <input type="email" placeholder="Enter Your Email" />
              <button className="btnn prebtn" type="submit">Subscribe</button>
            </form>
            {/* <Socialicons /> */}
            <h2>{t("Contact")}</h2>
            <a href="mailto:contact@gaiaworld.com">contact@gaiaworld.com</a>
          </div>
        </div>
        <div className="prowedicon">
          <h2>{t("Powered by")}</h2>
          <a href="#">
            <img src={radicle} alt=""/>
          </a>
        </div>
        <Socialicons isFixed />
      </div>
    </footer>
  );
};

export default Footer;
