import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import firstslide from "../Assets/Images/1a.png";
import firstslidesec from "../Assets/Images/1b.png";
import secslide from "../Assets/Images/2a.png";
import secslidesec from "../Assets/Images/2b.png";
import thirdslide from "../Assets/Images/3a.png";
import thirdslidesec from "../Assets/Images/3b.png";
import fourslide from "../Assets/Images/4a.png";
import fourslidesec from "../Assets/Images/4b.png";
import fiveslide from "../Assets/Images/5a.png";
import fiveslidesec from "../Assets/Images/5b.png";


const Productslider = () => {
    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        fade: true,
        nav: true,
        slidesToScroll: 1,
      };
  return (
    <div id="mainwrapper" className="ourproducts">
      <section className="testimonal" id="GameModes">
        <Slider {...settings}>
          <div className="slid">
            <div className="monstor-slide">
              <div className="slider-left-img">
                <img src={firstslide} />
              </div>
              <div className="monstor-content">
                <h3>Our products</h3>
                <h2>pvp battles</h2>
                <p>
                  {" "}
                  Create your team and go to war to earn as much $GG possible.
                  Gaia PVP Beta plans to go live late April. You can fight your
                  way across Gaia Everworld and earn the whole time.{" "}
                </p>
              </div>
              <div className="slider-left-img right-side">
                <img src={firstslidesec} />
              </div>
            </div>
          </div>

          <div className="slid">
            <div className="monstor-slide">
              <div className="slider-left-img">
                <img src={secslide} />
              </div>
              <div className="monstor-content">
                <h3>Our products</h3>
                <h2>legion</h2>
                <p>
                  Fight alone or join a clan while you fight for power or peace
                  in legion mode. For every battle won or successfully defended,
                  players can earn $GG. A great way to team up and enjoy the
                  gameplay in Gaia Everworld.
                </p>
              </div>
              <div className="slider-left-img right-side">
                <img src={secslidesec} />
              </div>
            </div>
          </div>

          <div className="slid">
            <div className="monstor-slide">
              <div className="slider-left-img">
                <img src={thirdslide} />
              </div>
              <div className="monstor-content">
                <h3>Our products</h3>
                <h2>Adventure</h2>
                <p>
                  An immersive MMORPG adventure game full of quests, strategy,
                  and wars to be won. Play along an exciting path with numerous
                  Gaias with this exciting mode. Planned to be live in late
                  2028.{" "}
                </p>
              </div>
              <div className="slider-left-img right-side">
                <img src={thirdslidesec} />
              </div>
            </div>
          </div>

          <div className="slid">
            <div className="monstor-slide">
              <div className="slider-left-img">
                <img src={fourslide} />
              </div>
              <div className="monstor-content">
                <h3>Our products</h3>
                <h2>land</h2>
                <p>
                  {" "}
                  Own property on the safezone in Gaia Everworld. Build a home
                  or a shop on your property. Renovate your land to your liking,
                  with countless options for designs.
                </p>
              </div>
              <div className="slider-left-img right-side">
                <img src={fourslidesec} />
              </div>
            </div>
          </div>

          <div className="slid">
            <div className="monstor-slide">
              <div className="slider-left-img">
                <img src={fiveslide} />
              </div>
              <div className="monstor-content">
                <h3>Our products</h3>
                <h2>farming</h2>
                <p>
                  After obtaining $GAIA, add liquidity to your tokens through
                  our farming protocol, a means of earning interest on your
                  $GAIA. Earn in more than one way when you play in Gaia
                  Everworld!{" "}
                </p>
              </div>
              <div className="slider-left-img right-side">
                <img src={fiveslidesec} />
              </div>
            </div>
          </div>
        </Slider>
      </section>
    </div>
  );
};

export default Productslider;
