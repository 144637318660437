import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../Assets/Images/Logo.svg";
import redicaleion from "../../Assets/Images/redicaleion.png";
import earth from "../../Assets/Images/earth.svg";
import menuicon from "../../Assets/Images/menuicon.svg";
import { useTranslation } from "react-i18next";
import Sidebar from "../Sidebar";
import Socialicons from "../socialicons";

const Header = ({ darkMode, setDarkMode }) => {
  const { t, i18n } = useTranslation();
  const [OpenMenu, setOpenMenu] = useState(false);
  return (
    <header className="head">
      <a href="" className="redicle" target="_blank">
      <img src={redicaleion} alt="redicale" />
      </a>
      <a href="/" className="logodiv">
        <img src={logo} alt="logo" />
      </a>
      
      <div className="rightnav">
      <Socialicons />
        <ul>
          <li>
            <Link to="" className="translator">
              <img src={earth} />
              {t("EN")}
            </Link>
          </li>
          <li>
            <Link to="" className="btnn">
              {t("Login")}
            </Link>
          </li>
          
          <div className="Language">
            <ul>
              <li>
                <button className="active">EN</button>
              </li>
              <li>
                <button>FIL</button>
              </li>
              <li>
                <button>VI</button>
              </li>
              <li>
                <button>ZHO</button>
              </li>
              <li>
                <button>ES</button>
              </li>
            </ul>
          </div>
        </ul>
      </div>
      <div className="mobile-menu-icon">
        <img
          src={menuicon}
          onClick={() => {
            setOpenMenu(!OpenMenu);
          }}
        />
      </div>
      <div className={`mobile-menu ${OpenMenu ? "open-menu" : ""}`} onClick={() => setOpenMenu(false)} >
        <Sidebar onclose={() => setOpenMenu(false)} />
      </div>
    </header>
  );
};

export default Header;
