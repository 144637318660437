import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import blog from "../../Assets/Images/blog.png";
import Abouttabs from "../../Components/abouttabs";
import Innerhero from '../../Components/innerbanner';
import { getMediumPost } from "../../Services/medium.service";
import s1 from "../../Assets/Images/s1.png";
import s2 from "../../Assets/Images/s2.png";
import s3 from "../../Assets/Images/s3.png";
const GaiaUpdates = () => {

  const [articles, setArticles] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [totalArticles, setTotalArticles] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    getAllMediumArticles();
  }, []);

  const getAllMediumArticles = async () => {
    try {
      const articlesFromMedium = await getMediumPost();
      setTotalArticles(articlesFromMedium);
      let articlesToLoad = [];
      const totalPages = Math.ceil(articlesFromMedium.length / 6);
      if (totalPages > 1) {
        articlesToLoad = articlesFromMedium.slice(0, 6);
        setPageCount(totalPages);
      } else {
        articlesToLoad = [...articlesFromMedium];
        setPageCount(1);
      }
      setCurrentPage(1);
      setArticles(articlesToLoad);
    } catch (error) {
      console.error(error);
    }
  };

  const { t, i18n } = useTranslation();
  function handleLoadMore() {
    let articlesToLoad;
    if (currentPage + 1 === pageCount) {
      articlesToLoad = totalArticles.slice(0, totalArticles?.length);
    } else {
      articlesToLoad = totalArticles.slice(0, currentPage + 1 * 6);
    }
    setCurrentPage(currentPage + 1);
    setArticles(articlesToLoad);
  }
  return (
    <div id="mainwrapper" className="aboutpage ">
 
     <Innerhero />
      <Abouttabs />
     
      <section className="gaiablog blogloader">
        <div className="container">
          <h3>GAIA blog </h3>
          <div className="bloglist columns2">
            {articles?.map((article, index) => {
              return (
                <div className="blogitem serimg" key={index}>
                  <div className="featuredimg">
                    <img src={article?.thumbnail} />
                  </div>
                  <div className="blogcontent">
                    <h4>{article?.title}</h4>
                    <p
                      dangerouslySetInnerHTML={{ __html: article?.description }}
                    ></p>
                    <a target="_blank" href={article?.link} className="btnn prebtn">
                      {t("Read More")}
                    </a>
                  </div>
                </div>
              );
            })}
            {pageCount > currentPage && (
              <button className="btnn secndbtn" onClick={() => handleLoadMore()}>
                {t("Load More")}
              </button>
            )}
          </div>
        </div>
      </section>

      <section className="serlearn">
          <div className="container">
            <h3>learn more</h3>
            <div className="columns2">
              <div className="serimg">
              <img src={s1} />
              </div>
              <div className="serimg">
              <img src={s2} />
              </div>
              <div className="serimg">
              <img src={s3} />
              </div>
            </div>
          </div>
       </section>
    </div>
  );
};

export default GaiaUpdates;
