import React, { useState } from "react";
import Innerhero from '../../Components/innerbanner';
import Mappins from '../../Assets/Images/map-for-pins.png'
 
const Map = () => {
  return (
    <div className="map " partners>
    <section className="comingsoon">
        <div className="container">
            <h1>Coming Soon</h1>
        </div>
    </section>
        </div>
//     <div id="mainwrapper" className="map ">

//      <Innerhero />
//      <section className="partners mapcont">
//      <div className="container ">
//          {/* <img src={Mappins} alt="mapp" /> */}
//      <p><b>The Safezone is a region within Gaia Everworld where players can meet up, create, experiences and monetize content on the land they own or rent. The Safezone will consist of 237,732 parcels of land called Eever. Each Eever is is a unique, non-fungilble and transferable digital asset identified by cartesian coordinates (X,Y), which gives owners the rights to their piece of the metaverse.
// </b></p>
//               <p>
//               Owners of Eever will be able to create and host experiences upon them. These experiences can range from creating in-game quests, farms, building arenas to host e-sports competitions, developing mini-games, hosting NFT galleries, social hubs and much more. Full ownership of the land means owners can monetize anything they build, potentially creating passive income opportunities. They can also be used to host contests and events.
//               </p>
//               <p>
//               Eever and $Gaia token holders will be involved in the governance and therefore the development of the Gaia Everworld ecosystem. Owners will be given voting rights determining and governing the future of the Everworld as it gradually moves towards fully decentralised autonomous organisation (DAO).
//               </p>
//               <p>
//               Our Drag & Drop tool makes it simple for anyone to build their own property in the metaverse. Alll of our in-game facilities that will be found in Adventure Mode, as well as other blueprints of buildings created by the keen developers within the Gaia Community, will be available. Owners and players will be able to create the virtual estates they have always desired. 
//               </p>
//               <p>
//               Opportunities for advertising will be plenty in the Everworld. By using billboards in high-traffic and popular areas, advertisers will be able to promote their products, services, and events to the citizens of the metaverse. Brands may even utilise their land to develop their own products within Gaia, creating novel experiences with their audiences in this new digital age.

//               </p>
//               <p><b>Finally, just as you can in the real world, owners will always be able to sell their land to other investors. Should they develop a virtual cash cow or popular property within the Everworld then that property will be very desirable to future investors.
// </b></p>
//      </div>
//      </section>
//     </div>
  );
};

export default Map;
