import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
const Innerhero = ({}) => {
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  return (
    <section className="hero-sec innerbanner">
    <div className="container">
        <div className="heordetail innercontent">
          <h1>gaia<br/> everworld</h1>
          <p>A strange beautiful world full of adventure, unique creatures, and savage monsters. In this immersive multi-region fantasy world players build their kingdoms, explore the lands, collect, breed and battle your Gaia Legionnaires. Choose from a selection of Heros to start your quest in this epic new game Gaia Everworld! </p>
        </div>
     </div>
</section>
  );
};

export default Innerhero;
