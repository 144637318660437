import React, { useState } from "react";
import { Link } from "react-router-dom";

const Privacy = () => {
  return (
    <div className="privacy-content">
      <div class="container">
        <div class="heading">
          <h2>Policy</h2>
        </div>
        <div class="privacy-content-list">
          <h3>
            <b>Effective date: </b>19th November 2021{" "}
          </h3>
          <p>
            Gaiaworld's Privacy Policy governs your visit and use to{" "}
            <Link to="/">www.gaiaworld.com</Link> {" "}
            website and Gaiaworld mobile application. The purpose of this
            Privacy Policy is to inform and explain how we manage, collect, use,
            posses, safeguard and disclose information that results from your
            use of our Service, which is subject to the privacy principles set
            out in the US Privacy Act 1974 and general law intended to protect
            your privacy, as amended from time to time, and where relevant by
            the European Union General Data Protection Regulations (EU) 2016/679
            (the GDPR), California Privacy Protection Act (the CalOPPA) and
            California Consumer Privacy Act (the CCPA).{" "}
          </p>
          <p>
            Protecting our customer's Personal Data and compliance with
            applicable laws is of the utmost importance to Gaiaworld and matter
            we take very seriously. We are committed to property managing,
            protecting, and processing your Personal Data in accordance with
            this Privacy Policy, which applies to all Personal Data we collect
            through our Website and/or other channels.{" "}
          </p>
          <p>
            We use your data to provide and improve Service. By using Service,
            you agree to the collection and use of information in accordance
            with this policy. Unless otherwise defined in this Privacy Policy,
            the terms used in this Privacy Policy have the same meanings as in
            our Terms and Conditions.
          </p>
        </div>
        <div class="privacy-content-list">
          <h3>1. DEFINITIONS</h3>
          <ul>
            <li>
              <strong>SERVICE</strong> means the{" "}
              <a href="/" target="blank">
                www.gaiaworld.com
              </a>{" "}
              website and Gaiaworld mobile application operated by Omnisoft ltd.{" "}
            </li>
            <li>
              <strong>PERSONAL DATA</strong> means data about a living
              individual who can be identified from those data (or from those
              and other information either in our possession or likely to come
              into our possession).
            </li>
            <li>
              <strong>USAGE DATA</strong> is data collected automatically either
              generated by the use of Service or from Service infrastructure
              itself (for example, the duration of a page visit).
            </li>
            <li>
              <strong>COOKIES</strong> are small files stored on your device
              (computer or mobile device).
            </li>
            <li>
              <strong>DATA CONTROLLER</strong> means a natural or legal person
              who (either alone or jointly or in common with other persons)
              determines the purposes for which and the manner in which any
              personal data are, or are to be, processed. For the purpose of
              this Privacy Policy, we are a Data Controller of your data.
            </li>
            <li>
              <strong>DATA PROCESSORS (OR SERVICE PROVIDERS)</strong> means any
              natural or legal person who processes the data on behalf of the
              Data Controller. We may use the services of various Service
              Providers in order to process your data more effectively.
            </li>
            <li>
              <strong>DATA SUBJECT</strong> is any living individual who is the
              subject of Personal Data.
            </li>
            <li>
              <strong>THE USER” </strong> is the individual using our Service.
              The User corresponds to the Data Subject, who is the subject of
              Personal Data.
            </li>
          </ul>
        </div>
        <div class="privacy-content-list">
          <h3>2. INTRODUCTION</h3>
          <p>
            <b>b2.1.</b> Gaiaworld (“us”, “we”, or “our”) operates{" "}
            <a href="/" target="blank">
              www.gaiaworld.com
            </a>{" "}
            and Gaiaworld mobile application (hereinafter referred to as
            “Service”, “Site”, “Website”).{" "}
          </p>
          <p>
            <b>2.2. </b> We use your data to provide and improve Service. By
            using Service, you agree to the collection and use of information in
            accordance with this policy. Unless otherwise defined in this
            Privacy Policy, the terms used in this Privacy Policy have the same
            meanings as in our Terms and Conditions.{" "}
          </p>
          <p>
            <b>2.3. </b> Our Terms and Conditions (“Terms”) govern all use of
            our Service and together with the Privacy Policy constitutes your
            agreement with us (“Agreement”).
          </p>
        </div>
        <div class="privacy-content-list">
          <h3>3. PURPOSE OF PERSONAL DATA</h3>
          <ul>
            <li>
              <strong>3.1.1. </strong>Enabling your use of the full
              functionality of the Website{" "}
            </li>
            <li>
              <strong>3.1.2. </strong>Carrying out our contractual obligations{" "}
            </li>
            <li>
              <strong>3.1.3. </strong>Responding to your requests (e.g.,
              answering your enquiries);
            </li>
            <li>
              <strong>3.1.4. </strong>Providing you appropriate content (e.g.
              the correct language and currency);
            </li>
            <li>
              <strong>3.1.5. </strong>Delivering you our newsletter (if you have
              subscribed to one);
            </li>
            <li>
              <strong>3.1.6. </strong>Analyzing and improving our business
              activities;
            </li>
            <li>
              <strong>3.1.7. </strong>Optimizing and improving the Website; and
            </li>
            <li>
              <strong>3.1.8. </strong>Operating, managing, and promoting our
              business.
            </li>
          </ul>
        </div>
        <div class="privacy-content-list">
          <h3>3.2. Legal grounds for processing of personal data</h3>
          <p>
            Depending on the circumstances in which you provide us with your
            personal data, the collection and processing of your personal data
            is carried out on the basis of one of the following legal grounds:
          </p>
          <ul>
            <li>
              <strong>3.1.1. </strong>Ensuring the performance of a contract;
            </li>
            <li>
              <strong> 3.2.2. </strong>Pursuing our legitimate business
              interests; or
            </li>
            <li>
              <strong> 3.2.3. </strong>If you have provided us with your
              explicit consent for collection and processing of your personal
              data for a particular purpose
            </li>
          </ul>
        </div>
        <div class="privacy-content-list">
          <h3>4. NON-PERSONAL DATA</h3>
          <ul>
            <li>
              <strong>4.1. Types of non-personal data.</strong> When you use the
              Website, we may collect certain non-personal data about your
              activities on the Website, such as your browser types, operating
              systems, URL addresses of websites clicked to and from the
              Website, and your other online behavior data. Such non-personal
              data does not allow us to identify you in any manner.
            </li>
            <li>
              <strong>4.2. Purposes of non-personal data. </strong>We may
              collect non-personal data for online analytics purposes only, such
              as:
            </li>
            <ul>
              <li>
                <strong>4.2.1.</strong> To analyze what kind of users visit the
                Website;
              </li>
              <li>
                <strong>4.2.2.</strong> To identify the channels through which
                the Website is accessed and used;
              </li>
              <li>
                <strong>4.2.3.</strong> To examine the relevance, popularity,
                and engagement rate with regard to the content available on the
                Website; and
              </li>
              <li>
                <strong>4.2.4.</strong> To personalize the Website to your
                specific needs.
              </li>
            </ul>
            <li>
              <strong>4.3. Aggregated data. </strong> In case your non-personal
              data is combined with certain elements of your personal data in a
              way that allows us to identify you, we will treat such aggregated
              data as personal data.
            </li>
          </ul>
        </div>
        <div class="privacy-content-list">
          <h3>5. YOUR CONSENT</h3>
          <ul>
            <li>
              <strong>5.1.</strong> By using the Website, you are consenting to
              the processing of your personal data as set forth in this Privacy
              Policy.
            </li>
            <li>
              <strong>5.2.</strong> In exceptional cases (where required by the
              applicable law), we will seek your consent. For example, we may
              seek your explicit prior consent for the following purposes:
            </li>
            <ul>
              <li>
                <strong>5.2.1. </strong>If we would like to provide you with any
                marketing communication (e.g., sending you a newsletter);
              </li>
              <li>
                <strong>5.2.2. </strong>If we intend to use your personal data
                for the purposes that are not indicated in this Privacy Policy;
              </li>
              <li>
                <strong>5.2.3. </strong>If we would like to disclose or transfer
                your personal data to third parties that are not indicated in
                this Privacy Policy;
              </li>
              <li>
                <strong>5.2.4. </strong>If we intend to collect any sensitive
                data from you; or
              </li>
              <li>
                <strong>5.2.5. </strong>If we significantly amend this Privacy
                Policy.{" "}
              </li>
            </ul>
          </ul>
        </div>
        <div class="privacy-content-list">
          <h3>6. INFORMATION COLLECTION AND USE</h3>
          <ul>
            <li>
              <strong>6.1. </strong>We collect several different types of
              information for various purposes to provide and improve our
              Service to you.
            </li>
          </ul>
        </div>
        <div class="privacy-content-list">
          <h3>7. TYPES OF DATA COLLECTED</h3>
          <ul>
            <li>
              <strong>7.1. Personal Data</strong>
            </li>
            <li>
              <strong>7.1.1.</strong>While using our Service, we may ask you to
              provide us with certain personally identifiable information that
              can be used to contact or identify you (“Personal Data”).
              Personally identifiable information may include, but is not
              limited to:
            </li>
            <ul>
              <li>
                <strong>7.1.1.1. </strong>Email address
              </li>
              <li>
                <strong>7.1.1.2. </strong>First name and last name
              </li>
              <li>
                <strong>7.1.1.3. </strong>Cookies and Usage Data
              </li>
            </ul>
            <li>
              <strong>7.1.2. </strong>We may use your Personal Data to contact
              you with newsletters, marketing or promotional materials and other
              information that may be of interest to you.
            </li>
            <li>
              <strong>7.1.3. </strong>You may opt out of receiving any, or all,
              of these communications from us by following the unsubscribe link.
            </li>
          </ul>
        </div>
        <div class="privacy-content-list">
          <h3>7.2. Usage Data</h3>
          <ul>
            <li>
              <strong>7.2.1.</strong>We may also collect information that your
              browser sends whenever you visit our Service or when you access
              Service by or through a mobile device (<b>“Usage Data”</b>).
            </li>
            <li>
              <strong>7.2.2. </strong>This Usage Data may include information
              such as your computer's Internet Protocol address (e.g. IP
              address), browser type, browser version, the pages of our Service
              that you visit, the time and date of your visit, the time spent on
              those pages, unique device identifiers and other diagnostic data.
            </li>
            <li>
              <strong>7.2.3. </strong>When you access Service with a mobile
              device, this Usage Data may include information such as the type
              of mobile device you use, your mobile device unique ID, the IP
              address of your mobile device, your mobile operating system, the
              type of mobile Internet browser you use, unique device identifiers
              and other diagnostic data.
            </li>
          </ul>
        </div>
        <div class="privacy-content-list">
          <h3>7.3. Tracking Cookies Data</h3>
          <ul>
            <li>
              <strong>7.3.1. </strong> We may use cookies and similar tracking
              technologies to track the activity on our Service and we hold
              certain information.
            </li>
            <li>
              <strong>7.3.2. </strong> Cookies are files with a small amount of
              data which may include an anonymous unique identifier. Cookies are
              sent to your browser from a website and stored on your device.
              Other tracking technologies are also used such as beacons, tags
              and scripts to collect and track information and to improve and
              analyze our Service.
            </li>
            <li>
              <strong>7.3.3. </strong> Cookies may also be used for other
              purposes on our Website.
            </li>
            <li>
              <strong>7.3.4. </strong> You can instruct your browser to refuse
              all cookies or to indicate when a cookie is being sent. However,
              if you do not accept cookies, you may not be able to use some
              portions of our Service.
            </li>
            <li>
              <strong>7.3.5. </strong> If you disable the use of cookies on your
              web browser or remove or reject specific cookies from our Website
              or linked sites than you may not be able to gain access to all of
              the content and facilities in those websites.
            </li>
            <li>
              <strong>7.3.6. </strong> Examples of Cookies we use:
            </li>
            <ul>
              <li>
                <strong>7.3.6.1. Session Cookies:</strong> We use Session
                Cookies to operate our Service.
              </li>
              <li>
                <strong>7.3.6.2. Preference Cookies:</strong> We use Preference
                Cookies to remember your preferences and various settings.
              </li>
              <li>
                <strong>7.3.6.3. Security Cookies:</strong> We use Security
                Cookies for security purposes.
              </li>
              <li>
                <strong>7.3.6.4. Advertising Cookies:</strong> Advertising
                Cookies are used to serve you with advertisements that may be
                relevant to you and your interests.
              </li>
            </ul>
            <li>
              <strong>7.4. Other Data. </strong> While using our Service, we may
              also collect the following information: sex, age, date of birth,
              place of birth, passport details, citizenship, registration at
              place of residence and actual address, telephone number (work,
              mobile), details of documents on education, qualification,
              professional training, employment agreements, non-disclosure
              agreements, information on bonuses and compensation, information
              on marital status, family members, social security (or other
              taxpayer identification) number, office location and other data.{" "}
            </li>
          </ul>
        </div>
        <div class="privacy-content-list">
          <h3>8. USE OF DATA </h3>
          <ul>
            <li>
              <strong>8.1. </strong>We may use the collected data for various
              purposes:
            </li>
            <ul>
              <li>
                <strong> 8.1.1. </strong>to provide and maintain our Service;
              </li>
              <li>
                <strong>8.1.1. </strong>to provide and maintain our Service;
              </li>
              <li>
                <strong>8.1.3. </strong>to allow you to participate in
                interactive features of our Service when you choose to do so;{" "}
              </li>
              <li>
                <strong>8.1.4. </strong>to provide customer support;{" "}
              </li>
              <li>
                <strong>8.1.5. </strong>to gather analysis or valuable
                information so that we can improve our Service;{" "}
              </li>
              <li>
                <strong>8.1.6. </strong>to monitor the usage of our Service;{" "}
              </li>
              <li>
                <strong>8.1.7. </strong>to detect, prevent and address technical
                issues;{" "}
              </li>
              <li>
                <strong>8.1.8. </strong>to fulfill any other purpose for which
                you provide it;{" "}
              </li>
              <li>
                <strong>8.1.9. </strong>to carry out our obligations and enforce
                our rights arising from any contracts entered into between you
                and us, including for billing and collection;
              </li>
              <li>
                <strong>8.1.10. </strong>to provide you with notices about your
                account and/or subscription, including expiration and renewal
                notices, email-instructions, etc.;
              </li>
              <li>
                <strong>8.1.11. </strong>to provide you with news, special
                offers and general information about other goods, services and
                events which we offer that are similar to those that you have
                already purchased or enquired about unless you have opted not to
                receive such information;
              </li>
              <li>
                <strong>8.1.12. </strong>in any other way we may describe when
                you provide the information;{" "}
              </li>
              <li>
                <strong>8.1.13. </strong>for any other purpose with your
                consent.{" "}
              </li>
            </ul>
          </ul>
        </div>
        <div class="privacy-content-list">
          <h3>9. RETENTION OF DATA</h3>
          <ul>
            <li>
              <strong>9.1. </strong>We will retain your Personal Data only for
              as long as is necessary for the purposes set out in this Privacy
              Policy. We will retain and use your Personal Data to the extent
              necessary to comply with our legal obligations (for example, if we
              are required to retain your data to comply with applicable laws),
              resolve disputes, and enforce our legal agreements and policies.
            </li>
            <li>
              <strong>9.2. </strong>We will also retain Usage Data for internal
              analysis purposes. Usage Data is generally retained for a shorter
              period, except when this data is used to strengthen the security
              or to improve the functionality of our Service, or we are legally
              obligated to retain this data for longer time periods.
            </li>
          </ul>
        </div>
        <div class="privacy-content-list">
          <h3>10. TRANSFER OF DATA</h3>
          <ul>
            <li>
              <strong>10.1. </strong>Your information, including Personal Data,
              may be transferred to and maintained on computers located outside
              of your state, province, country or other governmental
              jurisdiction where the data protection laws may differ from those
              of your jurisdiction.
            </li>
            <li>
              <strong>10.2. </strong>If you are located outside the British
              Virgin Islands and choose to provide information to us, please
              note that we transfer the data, including Personal Data, to
              British Virgin Islands and process it there.
            </li>
            <li>
              <strong>10.3. </strong>Your consent to this Privacy Policy
              followed by your submission of such information represents your
              agreement to that transfer.
            </li>
            <li>
              <strong>10.4. </strong>We will take all the steps reasonably
              necessary to ensure that your data is treated securely and in
              accordance with this Privacy Policy and no transfer of your
              Personal Data will take place to an organization or a country
              unless there are adequate controls in place including the security
              of your data and other personal information.
            </li>
          </ul>
        </div>
        <div class="privacy-content-list">
          <h3>11. DISCLOSURE OF DATA</h3>
          <ul>
            <li>
              <strong>11.1. </strong>We may disclose personal information that
              we collect, or you provide:
            </li>
            <ul>
              <li>
                <strong>11.1.1. </strong>Disclosure for Law Enforcement. Under
                certain circumstances, we may be required to disclose your
                Personal Data if required to do so by law or in response to
                valid requests by public authorities.
              </li>
              <li>
                <strong>11.1.2. </strong>Business Transaction. If we or our
                subsidiaries are involved in a merger, acquisition or asset
                sale, your Personal Data may be transferred.
              </li>
              <li>
                <strong>11.1.3. </strong>Other cases. We may disclose your
                information also:
              </li>
              <ul>
                <li>
                  <strong>11.1.3.1. </strong>to our subsidiaries and affiliates;
                </li>
                <li>
                  <strong>11.1.3.2. </strong>to contractors, service providers,
                  and other third parties we use to support our business;.
                </li>
                <li>
                  <strong>11.1.3.3. </strong>to fulfill the purpose for which
                  you provide it;
                </li>
                <li>
                  <strong>11.1.3.4. </strong>for the purpose of including your
                  company's logo on our website;
                </li>
                <li>
                  <strong>11.1.3.5. </strong>for any other purpose disclosed by
                  us when you provide the information;
                </li>
                <li>
                  <strong>11.1.3.6. </strong>with your consent in any other
                  cases;
                </li>
                <li>
                  <strong>11.1.3.7. </strong>if we believe disclosure is
                  necessary or appropriate to protect the rights, property, or
                  safety of the Company, our customers, or others.
                </li>
              </ul>
            </ul>
          </ul>
        </div>
        <div class="privacy-content-list">
          <h3>12. SECURITY OF DATA</h3>
          <ul>
            <li>
              <strong>12.1. </strong>The security of your data is important to
              us but remember that no method of transmission over the Internet
              or method of electronic storage is 100% secure. While we strive to
              use commercially acceptable means to protect your Personal Data,
              we cannot guarantee its absolute security.
            </li>
          </ul>
        </div>
        <div class="privacy-content-list">
          <h3>13. YOUR RIGHTS</h3>
          <ul>
            <li>
              <strong>13.1. </strong>You have the following rights in relation
              to your Data:
            </li>
            <li>
              <strong>13.1.1. </strong>Right to access - the right to request
              (i) copies of the information we hold about you at any time, or
              (ii) that we modify, update or delete such information. If we
              provide you with access to the information we hold about you, we
              will not charge you for this, unless your request is "manifestly
              unfounded or excessive." Where we are legally permitted to do so,
              we may refuse your request. If we refuse your request, we will
              tell you the reasons why.
            </li>
            <li>
              <strong>13.1.2. </strong>Right to correct - the right to have your
              Data rectified if it is inaccurate or incomplete.
            </li>
            <li>
              <strong>13.1.3. </strong>Right to erase - the right to request
              that we delete or remove your Data from our systems.
            </li>
            <li>
              <strong>13.1.4. </strong>Right to restrict our use of your Data-
              the right to "block" us from using your Data or limit the way in
              which we can use it.
            </li>
            <li>
              <strong>13.1.5. </strong>Right to data portability- the right to
              request that we move, copy or transfer your Data.
            </li>
            <li>
              <strong>13.1.5. </strong>Right to data portability- the right to
              request that we move, copy or transfer your Data.
            </li>
            <ul>
              <li>
                <strong>13.2.</strong>Right to data portability- the right to
                request that we move, copy or transfer your Data. To make
                enquiries, exercise any of your rights set out above, or
                withdraw your consent to the processing of your Data (where
                consent is our legal basis for processing your Data), please
                contact us via this e-mail address:{" "}
                <a href="mailto:contact@gaiaworld.com">contact@gaiaworld.com</a>
                .
              </li>
              <li>
                <strong>13.3.</strong>If you are not satisfied with the way a
                complaint you make in relation to your Data is handled by us,
                you may be able to refer your complaint to the relevant data
                protection authority.
              </li>
              <li>
                <strong>13.4.</strong> It is important that the Data we hold
                about you is accurate and current. Please keep us informed if
                your Data changes during the period for which we hold it.{" "}
              </li>
            </ul>
          </ul>
        </div>
        <div class="privacy-content-list">
          <h3>
            14. YOUR DATA PROTECTION RIGHTS UNDER GENERAL DATA PROTECTION
            REGULATIONS (GDPR){" "}
          </h3>
          <ul>
            <li>
              <strong>14.1. </strong>If you are a resident of the European Union
              (EU) and European Economic Area (EEA), you have certain data
              protection rights, covered by GDPR. – See more at
              https://eur-lex.europa.eu/eli/reg/2016/679/oj{" "}
            </li>
            <li>
              <strong>14.2. </strong>We aim to take reasonable steps to allow
              you to correct, amend, delete, or limit the use of your Personal
              Data.
            </li>
            <li>
              <strong>14.3. </strong>If you wish to be informed what Personal
              Data we hold about you and if you want it to be removed from our
              systems, please email us at{" "}
              <a href="mailto:contact@gaiaworld.com">contact@gaiaworld.com</a>.{" "}
            </li>
            <ul>
              <li>
                <strong>14.4.1. </strong>the right to access, update or to
                delete the information we have on you;
              </li>
              <li>
                <strong>14.4.2. </strong>the right of rectification. You have
                the right to have your information rectified if that information
                is inaccurate or incomplete;
              </li>
              <li>
                <strong>14.4.3. </strong>the right to object. You have the right
                to object to our processing of your Personal Data;{" "}
              </li>
              <li>
                <strong>14.4.4. </strong>the right of restriction. You have the
                right to request that we restrict the processing of your
                personal information;
              </li>
              <li>
                <strong>14.4.5. </strong>the right to data portability. You have
                the right to be provided with a copy of your Personal Data in a
                structured, machine-readable and commonly used format;
              </li>
              <li>
                <strong>14.4.6. </strong>the right to withdraw consent. You also
                have the right to withdraw your consent at any time where we
                rely on your consent to process your personal information;
              </li>
            </ul>
            <li>
              <strong>14.5. </strong>Please note that we may ask you to verify
              your identity before responding to such requests. Please note, we
              may not able to provide Service without some necessary data.
            </li>
            <li>
              <strong>14.6. </strong>You have the right to complain to a Data
              Protection Authority about our collection and use of your Personal
              Data. For more information, please contact your local data
              protection authority in the European Economic Area (EEA).
            </li>
          </ul>
        </div>
        <div class="privacy-content-list">
          <h3>
            15. YOUR DATA PROTECTION RIGHTS UNDER THE CALIFORNIA PRICVACY
            PROTECTION ACT (CalOPPA){" "}
          </h3>
          <ul>
            <li>
              <strong>15.1. </strong> CalOPPA is the first state law in the
              nation to require commercial websites and online services to post
              a privacy policy. The law's reach stretches well beyond California
              to require a person or company in the United States (and
              conceivable the world) that operates websites collecting
              personally identifiable information from California consumers to
              post a conspicuous privacy policy on its website stating exactly
              the information being collected and those individuals with whom it
              is being shared, and to comply with this policy. – See more at:{" "}
              <a href="https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/">
                https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/{" "}
              </a>{" "}
            </li>
            <li>
              <strong>15.2. </strong> According to CalOPPA we agree to the
              following:
            </li>
            <li>
              <strong>15.2.1.</strong> users can visit our site anonymously;
            </li>
            <li>
              <strong>15.2.2.</strong> our Privacy Policy link includes the word
              “Privacy”, and can easily be found on the page specified above on
              the home page of our website;
            </li>
            <li>
              <strong>15.2.3.</strong> users will be notified of any privacy
              policy changes on our Privacy Policy Page;
            </li>
            <li>
              <strong>15.2.4.</strong> users are able to change their personal
              information by emailing us at{" "}
              <a href="mailto:contact@gaiaworld.com">contact@gaiaworld.com</a>.
            </li>
            <li>
              <strong>15.3.</strong> Our Policy on “Do Not Track” Signals:
            </li>
            <li>
              <strong>15.3.1.</strong> We honor Do Not Track signals and do not
              track, plant cookies, or use advertising when a Do Not Track
              browser mechanism is in place. Do Not Track is a preference you
              can set in your web browser to inform websites that you do not
              want to be tracked.{" "}
            </li>
            <li>
              <strong>15.3.2.</strong> You can enable or disable Do Not Track by
              visiting the Preferences or Settings page of your web browser.
            </li>
          </ul>
        </div>
        <div class="privacy-content-list">
          <h3>
            16. YOUR DATA PROTECTION RIGHTS UNDER THE CALIFNIA CONSUMER PRIVACY
            ACT (CCPA)
          </h3>
          <ul>
            <li>
              <strong>16.1. </strong>If you are a California resident, you are
              entitled to learn what data we collect about you, ask to delete
              your data and not to sell (share) it. To exercise your data
              protection rights, you can make certain requests and ask us:
            </li>
            <li>
              <strong>16.2.</strong>What personal information we have about you.
              If you make this request, we will return to you:
            </li>
            <ul>
              <li>
                <strong>16.2.</strong> What personal information we have about
                you. If you make this request, we will return to you:
              </li>
              <li>
                <strong>16.2.1.</strong> The categories of personal information
                we have collected about you.
              </li>
              <li>
                <strong>16.2.2.</strong> The categories of sources from which we
                collect your personal information.
              </li>
              <li>
                <strong>16.2.3.</strong> The business or commercial purpose for
                collecting or selling your personal information.
              </li>
              <li>
                <strong>16.2.4.</strong> The categories of third parties with
                whom we share personal information.
              </li>
              <li>
                <strong>16.2.5.</strong> The specific pieces of personal
                information we have collected about you.
              </li>
              <li>
                <strong>16.2.6.</strong> A list of categories of personal
                information that we have sold, along with the category of any
                other company we sold it to. If we have not sold your personal
                information, we will inform you of that fact.
              </li>
              <li>
                <strong>16.2.7.</strong> A list of categories of personal
                information that we have disclosed for a business purpose, along
                with the category of any other company we shared it with.
              </li>
            </ul>
            <li>
              <strong>16.3.</strong> Please note, you are entitled to ask us to
              provide you with this information up to two times in a rolling
              twelve-month period. When you make this request, the information
              provided may be limited to the personal information we collected
              about you in the previous 12 months.
            </li>
            <li>
              <strong>16.4.</strong> To delete your personal information. If you
              make this request, we will delete the personal information we hold
              about you as of the date of your request from our records and
              direct any service providers to do the same. In some cases,
              deletion may be accomplished through de-identification of the
              information. If you choose to delete your personal information,
              you may not be able to use certain functions that require your
              personal information to operate.{" "}
            </li>
            <li>
              <strong>16.5.</strong> To stop selling your personal information.
              If you submit a request to stop selling your personal information,
              we will stop selling it. If you are a California resident, to
              opt-out of the sale of your personal information, send us an email
              with title “Do Not Sell My Personal Information” to submit your
              request.
            </li>
            <li>
              <strong>16.5.1.</strong> Please note, if you ask us to delete or
              stop selling your data, it may impact your experience with us, and
              you may not be able to participate in certain programs or
              membership services which require the usage of your personal
              information to function. But in no circumstances, we will
              discriminate against you for exercising your rights.
            </li>
            <li>
              <strong>16.5.2.</strong> To exercise your California data
              protection rights described above, please send your request(s) by
              one of the following means:
            </li>
            <li>
              <strong>16.5.2.1.</strong> By email:{" "}
              <a href="mailto:contact@gaiaworld.com">contact@gaiaworld.com</a>.{" "}
            </li>
            <li>
              <strong>16.6.</strong> Your data protection rights, described
              above, are covered by the CCPA, short for the California Consumer
              Privacy Act. To find out more, visit the official California
              Legislative Information website. The CCPA took effect on
              01/01/2020.{" "}
            </li>
          </ul>
        </div>
        <div class="privacy-content-list">
          <h3>17. ANALYTICS</h3>
          <ul>
            <li>
              <strong>17.1.</strong> We may use third-party Service Providers to
              monitor and analyze the use of our Service.
            </li>
            <li>
              <strong>17.1.1.</strong> Google Analytics
            </li>
            <li>
              <strong>17.1.2.</strong> Google Analytics is a web analytics
              service offered by Google that tracks and reports website traffic.
              Google uses the data collected to track and monitor the use of our
              Service. This data is shared with other Google services. Google
              may use the collected data to contextualise and personalise the
              ads of its own advertising network.
            </li>
            <li>
              <strong>17.1.3.</strong> For more information on the privacy
              practices of Google, please visit the Google Privacy Terms web
              page:{" "}
              <a href="https://policies.google.com/privacy?hl=en">
                https://policies.google.com/privacy?hl=en
              </a>
            </li>
            <li>
              <strong> 17.1.4.</strong> We also encourage you to review the
              Google's policy for safeguarding your data:
              https://support.google.com/analytics/answer/6004245.
            </li>
          </ul>
        </div>
        <div class="privacy-content-list">
          <h3>18. SERVICE PROVIDERS</h3>
          <ul>
            <li>
              <strong>18.1.</strong> We may employ third party companies and
              individuals to facilitate our Service (“Service Providers”),
              provide Service on our behalf, perform Service-related services or
              assist us in analyzing how our Service is used.
            </li>
            <li>
              <strong>18.2. </strong> These third parties have access to your
              Personal Data only to perform these tasks on our behalf and are
              obligated not to disclose or use it for any other purpose.{" "}
            </li>
          </ul>
        </div>
        <div class="privacy-content-list">
          <h3>19. LINKS TO OTHER SITES</h3>
          <ul>
            <li>
              <strong>19.1.</strong> Our Service may contain links to other
              sites that are not operated by us. If you click a third party
              link, you will be directed to that third party's site. We strongly
              advise you to review the Privacy Policy of every site you visit.
            </li>
            <li>
              <strong>19.2.</strong> We have no control over and assume no
              responsibility for the content, privacy policies or practices of
              any third party sites or services.
            </li>
            <li>
              <strong>19.3.</strong> These thirds party sites will have their
              own rules about the collection, use and disclosure of information.{" "}
            </li>
            <li>
              <strong>19.4.</strong> We encourage you to review the privacy
              policies of the other website you visit.{" "}
            </li>
          </ul>
        </div>
        <div class="privacy-content-list">
          <h3>20. OTHER IMPORTANT INFRORMATION</h3>
          <ul>
            <li>
              <strong>20.1. </strong>We may review, scan or analyze your
              communications on the Site for fraud prevention, risk assessment,
              regulatory compliance, investigation, product development,
              research, and customer support purposes.{" "}
            </li>
            <li>
              <strong>20.2. </strong>As part of our fraud prevention efforts, we
              scan and analyze messages to mask contact information and
              reference to other websites.
            </li>
            <li>
              <strong>20.3. </strong>In some cases, we may also scan, review, or
              analyze messages to debug, improve, and expand product offerings.
            </li>
          </ul>
        </div>
        <div class="privacy-content-list">
          <h3>21. CHILDREN'S PRIVACY</h3>
          <ul>
            <li>
              <strong>21.1. </strong>Our Services are not intended for use by
              children under the age of 13 (“<b>Child</b>” or “<b>Children</b>
              ”).{" "}
            </li>
            <li>
              <strong>21.2. </strong>We do not knowingly collect personally
              identifiable information from Children under 13. If you become
              aware that a Child has provided us with Personal Data, please
              contact us. If we become aware that we have collected Personal
              Data from Children without verification of parental consent, we
              take steps to remove that information from our servers.
            </li>
          </ul>
        </div>
        <div class="privacy-content-list">
          <h3>22. SECURITY </h3>
          <ul>
            <li>
              <strong>22.1. </strong>We are continuously implementing and
              updating administrative, technical, and physical security measures
              to help protect your information against unauthorized access,
              loss, destruction, or alteration. However, the Internet is not a
              100% secure environment so we can't guarantee the security of the
              transmission or storage of your information.{" "}
            </li>
          </ul>
        </div>
        <div class="privacy-content-list">
          <h3>23. CHANGES TO THIS PRIVACY POLICY </h3>
          <ul>
            <li>
              <strong>23.1.</strong> We may update our Privacy Policy from time
              to time. We will notify you of any changes by posting the new
              Privacy Policy on this page.
            </li>
            <li>
              <strong>23.2.</strong> We will let you know via email and/or a
              prominent notice on our Service, prior to the change becoming
              effective and update “effective date” at the top of this Privacy
              Policy.
            </li>
            <li>
              <strong>23.3.</strong> You are advised to review this Privacy
              Policy periodically for any changes. Changes to this Privacy
              Policy are effective when they are posted on this page.
            </li>
          </ul>
        </div>
        <div class="privacy-content-list">
          <h3>24. CONTACT US</h3>
          <ul>
            <li>
              <strong>24.1. </strong>If you have any questions about this
              Privacy Policy, please contact us:{" "}
            </li>
            <ul>
              <li>
                <strong>24.1.1. </strong>By email:{" "}
                <a href="mailto:contact@gaiaworld.com">contact@gaiaworld.com</a>
                .
              </li>
            </ul>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
