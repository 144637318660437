import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import radicle from "../../Assets/Images/radicle-gaming.png";
import unilayer from "../../Assets/Images/unilayer.png";
import polygon from "../../Assets/Images/polygon.png";
import au21 from "../../Assets/Images/au21.png";
import mexc from "../../Assets/Images/mexc.png";
import partner1 from "../../Assets/Images/partner1.png";
import partner2 from "../../Assets/Images/partner2.png";
import partner3 from "../../Assets/Images/partner3.png";
import partner4 from "../../Assets/Images/partner4.png";
import partner5 from "../../Assets/Images/partner5.png";
import partner6 from "../../Assets/Images/partner6.png";
import partner7 from "../../Assets/Images/partner7.png";
import partner8 from "../../Assets/Images/partner8.png";
import partner9 from "../../Assets/Images/partner9.png";
import partner10 from "../../Assets/Images/partner10.png";
import partner11 from "../../Assets/Images/partner11.png";
import partner12 from "../../Assets/Images/partner12.png";
import partner13 from "../../Assets/Images/partner13.png";
import partner14 from "../../Assets/Images/partner14.png";
import partner15 from "../../Assets/Images/partner15.png";
import partner16 from "../../Assets/Images/partner16.png";
import partner17 from "../../Assets/Images/partner17.png";
import partner18 from "../../Assets/Images/partner18.png";
import partner19 from "../../Assets/Images/partner19.png";
import partner20 from "../../Assets/Images/partner20.png";
import partner21 from "../../Assets/Images/partner21.png";
import partner22 from "../../Assets/Images/partner22.png";
import partner23 from "../../Assets/Images/partner23.png";
import partner24 from "../../Assets/Images/partner24.png";
import partner25 from "../../Assets/Images/partner25.png";
import partner26 from "../../Assets/Images/partner26.png";
import partner27 from "../../Assets/Images/partner27.png";
import partner28 from "../../Assets/Images/partner28.png";
import partner29 from "../../Assets/Images/partner29.png";
import partner30 from "../../Assets/Images/partner30.png";
import partner31 from "../../Assets/Images/partner31.png";
import community2 from "../../Assets/Images/community2.png";
import Slider from "../../Components/Productslider";
import a1 from "../../Assets/Images/a1.png";
import b1 from "../../Assets/Images/b1.png";
import d1 from "../../Assets/Images/d1.png";
import landd1 from "../../Assets/Images/landd1.png";
import sectionimng from "../../Assets/Images/sectionimng.svg";
import { saveAs } from "file-saver";

const Home = () => {
  const { t } = useTranslation();
  return (
    <div id="mainwrapper" className="homepage ">
      <section className="hero-sec">
        <video autoPlay loop width="100%" playsInline muted>
          <source
            src="assets/Videos/23-06-30-ShowreelGame.mp4"
            type="video/mp4"
          />
        </video>
        <div className="container">
          <div className="heordetail">
            <h1>
              Gaia Everworld Alpha<br></br> 1.0 <span> Now Live!</span>
            </h1>
            <p>
              Welcome to the dawn of a new era! Gaia Everworld Alpha 1.0 is now
              live and ready for testing. Delve into the depths of our expansive
              metaverse right from your Windows device today. Your feedback will
              shape the worlds of tomorrow.
            </p>
            <ul className="tbtns">
              <li>
                <a
                  href={"https://d2j04pqii01bv9.cloudfront.net/GaiaEverworld/GaiaEverworld.zip"}
                  rel="noreferrer"
                  className="sbutn prebtn"
                  download
                >
                  {t("Download")}
                </a>{" "}
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="dicsect">
        {/* <video
          autoPlay
          loop
          width="100%"
          playsInline
          muted
          className="dsktpvideo"
        >
          <source
            src="https://unilayer.com/gaiaeverworldv2/Building_on_Land_small.mp4"
            type="video/mp4"
          />
        </video>
        <video
          autoPlay
          loop
          width="100%"
          playsInline
          muted
          className="mbilevideo"
        >
          <source
            src="https://unilayer.com/gaiaeverworldv2/BuildingonLand_v02.mp4"
            type="video/mp4"
          />
        </video> */}
        <div className="container">
          <div className="abustsec">
            <div className="col col-l">
              <img src={sectionimng} alt="sectionimng" />
            </div>
            <div className="col">
              <h3>Discover gaia land</h3>
              <p>
                Embark on the first steps of a grand adventure with Gaia
                Everworld Alpha 1.0. Venture into our nascent metaverse, explore
                Gaia Land, and begin carving your path in the digital universe.
                The genesis of a new realm is at your fingertips.
              </p>
              <ul className="tbtns">
                <li>
                  <a
                    href="https://s3.ap-south-1.amazonaws.com/com.marliontech.assets/Gaia+Everworld/GaiaEverworld.zip"
                    className="btnn prebtn"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("Discover Gaia")}
                  </a>{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="playsect">
        <div className="container">
          <div className="headings">
            <h2>PLAY</h2>
            <h3>What is coming for Gaia Everworld? </h3>
          </div>
          <div className="columns2">
            <div className="col plybxes">
              <img src={a1} alt="" />
              <h3>Gaia Marketplace</h3>
              <a
                href="https://land.gaiaworld.com/"
                className="btnn prebtn centerbtn"
              >
                Go to Marketplace
              </a>
            </div>
            <div className="col plybxes">
              <img src={b1} alt="" />
              <h3>PVP BATTLES</h3>
              <p>Gaia PvP version one goes live in April</p>
            </div>
            <div className="col plybxes">
              <img src={landd1} alt="" />
              <h3>Gaia Land</h3>
              <a
                href="https://land.gaiaworld.com/map"
                className="btnn prebtn centerbtn"
              >
                Discover Gaia
              </a>
            </div>
            <div className="col plybxes">
              <img src={d1} alt="" />
              <h3>gaia Everworld adventure mode</h3>
              <p>
                A full blown MMORPG adventure game planed to go live in late
                2028
              </p>
            </div>
          </div>
        </div>
      </section>
      <Slider />

      <section className="joinsect">
        <div className="container">
          <div className="columns2 colwdth">
            <div className="col textpadl">
              <h3>Join Our Community</h3>
              <p>
                Anyone can participate in the BETA mode and win up to $80,000
                worth of rewards only for Beta players. Win in-game items such
                as Swords, shields, potions and so much more. Everyone has a
                chance to win $50,000 worth of land. What are you waiting for?
                Join our amazing world today!
              </p>
              <Link to="/" className="btnn prebtn textleft">
                {t("Join Us")}
              </Link>
            </div>
            <div className="col">
              <img src={community2} alt=""></img>
            </div>
          </div>
        </div>
      </section>
      <section className="partners">
        <div className="container">
          <h3 className="textcenter">investors and partners </h3>
          <div className="partners-logo">
            <img src={radicle} alt="Radicle Gaming" />
            <img src={unilayer} alt="Unilayer" />
            <img src={polygon} alt="Polygon" />
            <img src={au21} alt="AU21 Capital" />
            <img src={mexc} alt="MEXCB lobal" />
            <img src={partner10} alt="Cryptobees" />
            <img src={partner9} alt="ChainGUARDS" />
            <img src={partner8} alt="AussieCAPITAL" />
            <img src={partner7} alt="defiboost" />
            <img src={partner22} alt="OIG" />
            <img src={partner18} alt="Gaia" />
            <img src={partner20} alt="Brothehood" />
            <img src={partner16} alt="Avstar CAPITAL" />
            <img src={partner14} alt="Gaia" />
            <img src={partner12} alt="Gaia" />
            <img src={partner21} alt="Gaia" />
            <img src={partner17} alt="SHIMACAPITAL" />
            <img src={partner19} alt="Gaia" />
            <img src={partner15} alt="BLACKDRAGON" />
            <img src={partner13} alt="BASICS CAPITAL" />
            <img src={partner11} alt="M6" />
            <img src={partner1} alt="THUGS CAPITAL" />
            <img src={partner2} alt="PLUTO Digital" />
            <img src={partner5} alt="Gaia" />
            <img src={partner3} alt="PANDA CAPITAL" />
            <img src={partner6} alt="Exnetwork Captial" />
            <img src={partner4} alt="Fairum" />
            <img src={partner23} alt="Fairum" />
            <img src={partner24} alt="Fairum" />
            <img src={partner25} alt="Fairum" />
            <img src={partner26} alt="Fairum" />
            <img src={partner27} alt="Fairum" />
            <img src={partner28} alt="Fairum" />
            <img src={partner29} alt="Fairum" />
            <img src={partner30} alt="Fairum" />
            <img src={partner31} alt="Fairum" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
