import React from "react";
import { Link } from "react-router-dom";
import homevector from "../../Assets/Images/home.svg";
import pvp from "../../Assets/Images/trophy.svg";
import shopcount from "../../Assets/Images/store.svg";
import mapicon from "../../Assets/Images/nav.svg";
import giftpack from "../../Assets/Images/gift.svg";
import aboutinfo from "../../Assets/Images/info.svg";
import { useTranslation } from "react-i18next";
import language from "../../Assets/Images/Languagem.svg";
import Socialicons from "../socialicons";
import { useLocation } from "react-router-dom";

const Sidebar = ({ onclose }) => {
  const { pathname, hash } = useLocation();
  const { t } = useTranslation();
  return (
    <sidebar>
      <div className="container">
        <div className="logsect">
          <ul>
            <li
              onClick={onclose}
              className={hash === "" && pathname === "/" && "active"}
            >
              <Link to="/">
                <img src={homevector}  alt=""/>
                {t("Home")}
              </Link>
            </li>
            <li onClick={onclose} className={pathname === "/about" && "active"}>
              <Link to="/about">
                <img src={aboutinfo}  alt=""/>
                {t("about")}
              </Link>
            </li>
            <li onClick={onclose} className={pathname === "/our-products" && "active"}>
              <Link to="/our-products">
                <img src={pvp}  alt=""/>
                {t("Games")}
              </Link>
            </li>

            <li
              onClick={onclose}
              className={pathname === "/marketplace" && "active"}
            >
              <a href="https://land.gaiaworld.com">
                <img src={shopcount}  alt=""/>
                {t("MARKET")}
              </a>
            </li>

            <li onClick={onclose} className={pathname === "/map" && "active"}>
              <a href="https://land.gaiaworld.com/map">
                <img src={mapicon}  alt=""/>
                {t("Map")}
              </a>
            </li>

            <li
              onClick={onclose}
              className={pathname === "/rewards" && "active"}
            >
              <Link to="/rewards">
                <img src={giftpack}  alt=""/>
                {t("rewards")}
              </Link>
            </li>
          </ul>
        </div>
        <div className="rightnav desknone">
          <ul>
            <li onClick={onclose}>
              <Link to="" className="translator">
                <img src={language}  alt=""/>
                {t("EN")}
              </Link>
            </li>
            <li onClick={onclose}>
              <Link to="" className="btnn prebtn">
                {t("Login")}
              </Link>
            </li>
            <div className="Language">
              <ul>
                <li onClick={onclose}>
                  <button className="active">EN</button>
                </li>
                <li onClick={onclose}>
                  <button>FIL</button>
                </li>
                <li onClick={onclose}>
                  <button>VI</button>
                </li>
                <li onClick={onclose}>
                  <button>ZHO</button>
                </li>
                <li onClick={onclose}>
                  <button>ES</button>
                </li>
              </ul>
            </div>
          </ul>
          <div className="menust">
            <h2>{t("Socials")}</h2>
            <Socialicons />
            <h2>{t("Contact")}</h2>
            <a href="mailto:contact@gaiaworld.com">contact@gaiaworld.com</a>
          </div>
        </div>
      </div>
    </sidebar>
  );
};

export default Sidebar;
